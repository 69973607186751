import {
  EnergySource,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as EnergyProActions,
  create,
  displayFormErrors,
  hideNewEnergyProModal,
  updateField,
} from '../../actions/energy_pros';
import EditEnergyProForm from '../../components/EditEnergyProForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/energy_pros';
import { CreateNewText } from '../../types/global';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

interface OwnProps {
  siteId: number;
  panelId: number;
  currentSiteId: number;
  distributionPanelEnergySource: EnergySource;
}

interface Props extends OwnProps {
  onChange: (field: string, value: string | number) => void;
  onCreate: (panelId: number) => void;
  onClose: () => void;
  fields: EditableFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  isVisible: boolean;
  formErrorsVisible: boolean;
  displayFormErrors: (id: string) => void;
  apiError: ApiError;
}

function NewEnergyProModal(props: Props) {
  if (!props.isVisible) return null;

  const {
    siteId,
    fields,
    onChange,
    onClose,
    onCreate,
    isLoading,
    formErrors,
    formErrorsVisible,
    displayFormErrors,
    apiError,
    distributionPanelEnergySource,
  } = props;

  const onCircuitBreakerCreate = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      onCreate(props.panelId);
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>

      <Button disabled={isLoading} onClick={onCircuitBreakerCreate}>
        Create
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalTitle>{CreateNewText.ENERGY_PRO}</ModalTitle>
      <ModalContent>
        <EditEnergyProForm
          isNew
          siteId={siteId}
          fields={fields}
          onChange={onChange}
          formErrorsVisible={formErrorsVisible}
          formErrors={formErrors}
          distributionPanelEnergySource={distributionPanelEnergySource}
        />
        {renderAPIerror(apiError, EnergyProActions.CREATE_ENERGY_PRO_ERROR)}
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = ({ energyPros }: ApplicationState) => {
  return {
    isVisible: energyPros.showNewEnergyProModal,
    ...energyPros.editById['new'],
  };
};

const mapDispatchToProps = {
  onClose: hideNewEnergyProModal,
  onChange: (field: string, value: string | number) =>
    updateField('new', field, value),
  onCreate: (panelId: number) => create(panelId),
  displayFormErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewEnergyProModal);
