import {
  EnergySource,
  FirmwareGatewayModel,
  GenericErrors,
  PanelEnergySourceToAcPowerSystemId,
  PowerMeteringType,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';
import {
  InputField,
  Label,
  RadioButton,
  RadioGroup,
} from '@energybox/react-ui-library/dist/components';

import React, { useEffect } from 'react';
import SelectAcPowerSystem from '../../containers/Selects/SelectAcPowerSystem';
import SelectNetworkGroup from '../../containers/Selects/SelectNetworkGroup';
import { EditableFields } from '../../reducers/energy_pros';
import ModalFormContent from '../ModalFormContent';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { getFirmwares } from '../../actions/app';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';

interface Props {
  siteId: string | number;
  fields: EditableFields;
  onChange: (field: string, value: any) => void;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  isNew?: boolean;
  distributionPanelEnergySource: EnergySource;
  firmwareVersion?: string;
  setIsLatestFirmware?: (isLatestFirmware: boolean) => void;
}
/*
  Notes, there's some not so nice logic going on related to AC power system,
  I figure once we do a second iteration we can clean it up.

  If the form is new the user has the option to select real or virtual power systems.
  If virtual is selected you are able to choose one of the 3 virtual types, filtered from
  the connected select for power types.

  If it is nothing is selected or Real is selected, the e-pro will have it's power 
  point to the same power as the distribution panel it is attached to.
*/
const EditEnergyProForm = ({
  siteId,
  fields,
  onChange,
  formErrors,
  formErrorsVisible,
  isNew,
  distributionPanelEnergySource,
  firmwareVersion,
  setIsLatestFirmware,
}: Props) => {
  const {
    title,
    identifier,
    description,
    sampleFrequency,
    uuid,
    acPowerSystem,
    poweredFromDistributionPanel,
    networkGroupId,
  } = fields;

  const dispatch = useDispatch();
  const firmwares = useSelector(
    (state: ApplicationState) => state.app.firmwares
  );
  const [formattedUuid, setFormattedUuid] = React.useState<string>(uuid);

  const isLatestFirmware = React.useMemo(() => {
    if (!firmwareVersion || !firmwares) {
      return true;
    }

    const releaseFirmwares = firmwares.filter(
      firmware => firmware.type === 'RELEASE'
    );

    const latestFirmware = releaseFirmwares.reduce((prev, current) =>
      prev.version > current.version ? prev : current
    );

    return latestFirmware.version === firmwareVersion;
  }, [firmwareVersion, firmwares]);

  useEffect(() => {
    dispatch(getFirmwares({ gatewayModels: [FirmwareGatewayModel.ENERGYPRO] }));
  }, []);

  useEffect(() => {
    if (setIsLatestFirmware) {
      setIsLatestFirmware(isLatestFirmware);
    }
  }, [isLatestFirmware]);

  useEffect(() => {
    if (isNew && poweredFromDistributionPanel) {
      onChange(
        'acPowerSystem',
        PanelEnergySourceToAcPowerSystemId[distributionPanelEnergySource]
      );
    }
  }, [isNew, poweredFromDistributionPanel]);

  useEffect(() => {
    setFormattedUuid(uuid);
  }, [uuid]);

  /*
    if Power Metering Type REAL, auto selects ac power system (powered by DP)
    and disables the ac power system dropdown
  */

  const onPowerMeteringTypeChange = (type: PowerMeteringType) => {
    const isPoweredFromDistributionPanel = type === PowerMeteringType.REAL;

    onChange('poweredFromDistributionPanel', isPoweredFromDistributionPanel);

    if (isPoweredFromDistributionPanel) {
      onChange(
        'acPowerSystem',
        PanelEnergySourceToAcPowerSystemId[distributionPanelEnergySource]
      );
    }
  };

  const onChangeUuid = value => {
    const formattedUuid =
      value
        .replace(/[^0-9A-Fa-f]/g, '')
        ?.slice(0, 12)
        ?.toUpperCase()
        ?.match(/.{1,2}/g)
        ?.join(':') || '';
    setFormattedUuid(formattedUuid);
    onChange('uuid', formattedUuid);
  };

  return (
    <div>
      <ModalFormContent>
        <div>
          <Label required htmlFor="title">
            Energy Pro Name
          </Label>
        </div>
        <div>
          <InputField
            id="title"
            type="text"
            name="title"
            value={title}
            onChange={e => onChange('title', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.title}
          />
        </div>
        <div>
          <Label htmlFor="identifier">Identifier</Label>
        </div>
        <div>
          <InputField
            id="identifier"
            type="text"
            name="identifier"
            value={identifier}
            onChange={e => onChange('identifier', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.identifier}
          />
        </div>

        <div>
          <Label htmlFor="description">Description</Label>
        </div>
        <div>
          <InputField
            id="description"
            type="text"
            name="description"
            placeholder=""
            value={description}
            onChange={e => onChange('description', e.currentTarget.value)}
          />
        </div>

        <div>
          <Label required htmlFor="type">
            UUID
          </Label>
        </div>
        <div>
          <InputField
            id="uuid"
            type="text"
            name="uuid"
            placeholder=""
            value={formattedUuid}
            onChange={e => onChangeUuid(e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.uuid}
          />
        </div>

        {!isNew && (
          <>
            <div>
              <Label htmlFor="type">Firmware</Label>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                fontSize: '0.75rem',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  padding: '0.5rem 0rem',
                }}
              >
                v{firmwareVersion || global.NOT_AVAILABLE}
              </div>
              {!isLatestFirmware && (
                <div style={styles.updateFirmwareWarning}>
                  <WarningIcon size={16} />
                  <span>
                    <b>Update Firmware</b> Not the latest release version
                  </span>
                </div>
              )}
            </div>
          </>
        )}

        <div>
          <Label required htmlFor="type">
            Network Group
          </Label>
        </div>
        <div>
          <SelectNetworkGroup
            onSelect={(networkGroupId: number) =>
              onChange('networkGroupId', networkGroupId)
            }
            siteId={siteId}
            value={Number(networkGroupId)}
          />
        </div>

        <div>
          <Label htmlFor="type">Power Metering Type</Label>
        </div>
        <div>
          <RadioGroup>
            <RadioButton
              onChange={() => onPowerMeteringTypeChange(PowerMeteringType.REAL)}
              checked={poweredFromDistributionPanel}
              label={PowerMeteringType.REAL}
              value={PowerMeteringType.REAL}
            />
            <RadioButton
              onChange={() =>
                onPowerMeteringTypeChange(PowerMeteringType.VIRTUAL)
              }
              checked={!poweredFromDistributionPanel}
              label={PowerMeteringType.VIRTUAL}
              value={PowerMeteringType.VIRTUAL}
            />
          </RadioGroup>
        </div>

        <div>
          <Label htmlFor="type">AC Power System</Label>
        </div>
        <div>
          <SelectAcPowerSystem
            powerMeteringType={
              poweredFromDistributionPanel
                ? PowerMeteringType.REAL
                : PowerMeteringType.VIRTUAL
            }
            disabled={poweredFromDistributionPanel}
            error={formErrorsVisible && !!formErrors.acPowerSystem}
            value={acPowerSystem}
            onSelect={id => onChange('acPowerSystem', id)}
          />
        </div>

        <div>
          <Label required htmlFor="sampleFrequency">
            Sample Frequency (Seconds)
          </Label>
        </div>
        <div>
          <InputField
            id="sampleFrequency"
            type="number"
            name="sampleFrequency"
            min="2"
            placeholder=""
            value={sampleFrequency}
            onChange={e => onChange('sampleFrequency', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.sampleFrequency}
            customErrorText="Sample Frequency must be 2 or above"
          />
        </div>
      </ModalFormContent>
      <ModalFormContent>
        <Label>* Mandatory fields</Label>
      </ModalFormContent>
    </div>
  );
};

export default EditEnergyProForm;

const styles: { [key: string]: React.CSSProperties } = {
  updateFirmwareWarning: {
    backgroundColor: '#EF740F25',
    padding: '0.66rem 0.2rem',
    width: '100%',
    fontSize: '0.60rem',
    gap: '0.2rem',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#EF740F75',
  },
};
