import { App } from '../reducers/app';
export const featureFlagOrganizationId = parseInt(
  process.env.REACT_APP_ENERGYBOX_ORG || '275',
  10
);

export const canAccessFeatures = (app: App, orgWhiteList?: number[]) => {
  const orgId =
    (app.currentUser && app.currentUser.organizationId) ??
    app.currentOrganizationId;

  const isWhiteListedOrgInProps = orgId && (orgWhiteList || []).includes(orgId);

  return (
    orgId === featureFlagOrganizationId ||
    isWhiteListedOrgInProps ||
    process.env.NODE_ENV === 'development' ||
    false
  );
};
