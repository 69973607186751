import { Button, Modal } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as DistributionPanelActions,
  destroy,
  hideDeleteDistributionPanelModal,
  unstageDelete,
} from '../../actions/distribution_panel';
import history from '../../history';
import { ApplicationState } from '../../reducers';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

interface Props {
  isVisible: boolean;
  primedForDelete: number;
  onDelete: (id: number) => void;
  onClose: () => void;
  unstageDelete: () => void;
  apiError: ApiError;
  redirectAfterDelete?: boolean;
  panelTitle?: string;
}

class DeleteDistributionPanelModal extends React.Component<Props> {
  render() {
    if (!this.props.isVisible) return null;

    const {
      onDelete,
      onClose,
      apiError,
      primedForDelete,
      redirectAfterDelete,
      panelTitle,
    } = this.props;

    const confirmDelete = (id: number) => {
      onDelete(id);
      if (redirectAfterDelete) {
        history.goBack();
      }
    };

    const actions = (
      <>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>

        <Button
          onClick={() => {
            confirmDelete(primedForDelete);
          }}
        >
          Delete
        </Button>
      </>
    );
    return (
      <Modal actions={actions}>
        <div style={{ padding: '1rem 2rem' }}>
          <div style={{ textAlign: 'justify', textAlignLast: 'center' }}>
            <div style={{ padding: '1rem 0' }}>
              Are you sure you want to delete{' '}
              {panelTitle ? (
                <span style={{ fontWeight: 'bold' }}>{panelTitle}</span>
              ) : (
                'this distribution panel'
              )}
              ?
            </div>
            {renderAPIerror(
              apiError,
              DistributionPanelActions.DELETE_DISTRIBUTION_PANEL_ERROR
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ distributionPanels }: ApplicationState) => {
  return {
    panelTitle:
      distributionPanels.distributionPanelsById[
        distributionPanels.primedForDelete
      ] &&
      distributionPanels.distributionPanelsById[
        distributionPanels.primedForDelete
      ].title,
    isVisible: distributionPanels.showDeleteDistributionPanelModal,
    primedForDelete: distributionPanels.primedForDelete,
    apiError:
      distributionPanels.editById[distributionPanels.primedForDelete] &&
      distributionPanels.editById[distributionPanels.primedForDelete].apiError,
  };
};

const mapDispatchtoProps = (dispatch: any) => ({
  onDelete: id => {
    dispatch(destroy(id));
  },
  onClose: () => {
    dispatch(unstageDelete());
    dispatch(hideDeleteDistributionPanelModal());
  },
  unstageDelete: () => {
    dispatch(unstageDelete());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(DeleteDistributionPanelModal);
