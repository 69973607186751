import {
  EnergyDeviceType,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import {
  InputField,
  Label,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { Actions as EnergyDeviceActions } from '../../actions/energy_devices';
import { Actions as EnergyProActions } from '../../actions/energy_pros';
import SelectEnergyPro from '../../containers/Selects/SelectEnergyPro';
import { EditableFields } from '../../reducers/energy_devices';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import ModalFormContent from '../ModalFormContent';

interface Props {
  fields: EditableFields;
  onChange: (field: string, value: any) => void;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  panelId?: number;
  apiError: ApiError;
  predeterminedBus?: number;
  newEnergyDeviceType?: EnergyDeviceType;
}

type Bus = {
  name: string;
  id: number;
};

const EditEnergyDeviceForm = ({
  fields,
  onChange,
  formErrors,
  formErrorsVisible,
  panelId,
  apiError,
  predeterminedBus,
  newEnergyDeviceType,
}: Props) => {
  const { title, description, energyProId, bus } = fields;

  const BUSINFO = [
    {
      name: 'Bus I',
      id: 1,
    },
    {
      name: 'Bus II',
      id: 2,
    },
  ];

  const selectedBus = !predeterminedBus
    ? BUSINFO.find((b: Bus) => b.id === bus)
    : BUSINFO.find((b: Bus) => b.id === predeterminedBus);

  return (
    <div>
      <ModalFormContent>
        <div>
          <Label required htmlFor="title">
            Device Name
          </Label>
        </div>
        <div>
          <InputField
            id="title"
            type="text"
            name="title"
            value={title}
            onChange={e => onChange('title', e.currentTarget.value)}
            error={formErrorsVisible && !!formErrors.title}
          />
        </div>

        <div>
          <Label htmlFor="description">Description</Label>
        </div>
        <div>
          <InputField
            id="description"
            type="text"
            name="description"
            placeholder=""
            value={description}
            onChange={e => onChange('description', e.currentTarget.value)}
          />
        </div>

        {panelId && (
          <>
            <div>
              <Label required htmlFor="type">
                Energy Pro
              </Label>
            </div>
            <div>
              <SelectEnergyPro
                error={formErrorsVisible && !!formErrors.energyProId}
                value={energyProId}
                onSelect={selectedId => onChange('energyProId', selectedId)}
                panelId={panelId}
                newEnergyDeviceType={newEnergyDeviceType}
              />
            </div>
          </>
        )}

        <div>
          <Label required htmlFor="type">
            Port in
          </Label>
        </div>
        <div>
          <Select
            disabled={!!predeterminedBus}
            variant={'select'}
            title={selectedBus?.name}
            error={formErrorsVisible && !!formErrors.bus}
          >
            {BUSINFO.map((bus: Bus) => (
              <SelectItem
                key={bus.id}
                isSelected={bus.id === selectedBus?.id}
                onSelect={() => onChange('bus', bus.id)}
              >
                {bus.name}
              </SelectItem>
            ))}
          </Select>
        </div>
      </ModalFormContent>

      {renderAPIerror(
        apiError,
        EnergyProActions.APPEND_ENERGY_DEVICE_ERROR,
        EnergyDeviceActions.PATCH_ENERGY_DEVICE_ERROR
      )}

      <ModalFormContent>
        <Label>* Mandatory fields</Label>
      </ModalFormContent>
    </div>
  );
};

export default EditEnergyDeviceForm;
