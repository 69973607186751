import {
  GatewayConnectivityChip,
  RFConnectivityChip,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { WarningIcon } from '@energybox/react-ui-library/dist/icons';
import {
  ControlBoard,
  DeviceType,
  Firmware,
  Gateway,
  GatewayInfo,
  GatewayOnlineStatus,
  GatewayStatus,
  Vendor,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';

import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import { DeviceStatusById } from '../reducers/deviceStatus';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';
import { doTimestampsContainDefaults } from './devices';

const siteControllerModelNames = [
  'ENERGYBOX_CB',
  'ENERGYBOX_CB_US_10',
  'ENERGYBOX_CB_US_6',
  'ENERGYBOX_CB_EU_6',
];

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
export const OnlineStatus: React.FunctionComponent<{
  gatewayOnlineStatus?: GatewayOnlineStatus;
}> = ({ gatewayOnlineStatus }) => {
  if (!gatewayOnlineStatus) {
    return <>{global.NOT_AVAILABLE}</>;
  }

  return (
    <strong>{gatewayOnlineStatus.onlineState ? 'online' : 'offline'}</strong>
  );
};

export const LastCheckIn: React.FunctionComponent<{
  gatewayOnlineStatus?: GatewayOnlineStatus;
}> = ({ gatewayOnlineStatus }) => {
  if (!gatewayOnlineStatus) {
    return <>{global.NOT_AVAILABLE}</>;
  }

  if (!gatewayOnlineStatus.timestamp) {
    return <>{global.NOT_AVAILABLE}</>;
  }

  return (
    <>
      {formatDistance(parseISO(gatewayOnlineStatus.timestamp), new Date(), {
        addSuffix: true,
        includeSeconds: true,
      })}
    </>
  );
};

export const ConnectionType: React.FunctionComponent<{
  gatewayStatus?: GatewayStatus;
}> = ({ gatewayStatus }) => {
  if (!gatewayStatus || !gatewayStatus.networkType) {
    return <>{global.NOT_AVAILABLE}</>;
  }

  return (
    <span
      title={
        gatewayStatus.networkType && gatewayStatus.lteRssi
          ? `${gatewayStatus.lteRssi}dBm`
          : `${gatewayStatus.networkType}`
      }
    >
      <GatewayConnectivityChip
        size={16}
        networkInterface={gatewayStatus.networkType}
        signalQuality={gatewayStatus.signalQuality}
      />
    </span>
  );
};

export const ThermostatConnectionType: React.FunctionComponent<{
  vendor: Vendor;
  deviceId: string;
}> = ({ vendor, deviceId }) => {
  const deviceStatusById: DeviceStatusById = useSelector<
    ApplicationState,
    DeviceStatusById
  >(state => state.deviceStatusById);

  if (vendor !== 'energybox') {
    return <>{global.NOT_AVAILABLE}</>;
  }
  // device status has been subscribed in DeviceOnlineState component from GatewaysTable
  // add subscription to device status if it's used in a different component
  const deviceStatus = deviceStatusById[deviceId];

  if (!deviceStatus || deviceStatus.onlineState === undefined) {
    return <>{global.NOT_AVAILABLE}</>;
  }

  // Not available if the timestamp is default
  const isDefaultTimestamp =
    deviceStatus &&
    doTimestampsContainDefaults([
      {
        timestamp: deviceStatus.timestamp,
        onlineStateUpdatedAt: deviceStatus.onlineStateUpdatedAt,
        onlineState: deviceStatus.onlineState,
      },
    ]);

  if (isDefaultTimestamp) {
    return <>{global.NOT_AVAILABLE}</>;
  }

  return (
    <span
      title={
        deviceStatus.onlineState && deviceStatus.signalStrength
          ? `${deviceStatus.signalStrength}dBm`
          : 'No Signal'
      }
    >
      <RFConnectivityChip
        online={deviceStatus.onlineState}
        signalQuality={deviceStatus.signalStrength}
        size={18}
      />
    </span>
  );
};

export const FirmwareVersion: React.FunctionComponent<{
  model?: DeviceType;
  gatewayInfo?: GatewayInfo;
  releaseFirmwares?: Firmware[];
}> = ({ model, gatewayInfo, releaseFirmwares }) => {
  const [isLatestFirmware, setIsLatestFirmware] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!gatewayInfo?.firmwareVersion || !releaseFirmwares) {
      return;
    }

    const modelText = siteControllerModelNames.includes(model as string)
      ? 'ENERGYBOX_CB'
      : model;
    const releaseFirmwaresForModel = releaseFirmwares.filter(
      firmware =>
        (firmware.gatewayModels as string[])?.includes(modelText as string) ??
        false
    );

    if (releaseFirmwaresForModel.length === 0) {
      return;
    }

    const latestRelease = releaseFirmwaresForModel[0];
    setIsLatestFirmware(latestRelease.version === gatewayInfo.firmwareVersion);
  }, [gatewayInfo?.firmwareVersion, releaseFirmwares]);

  if (!gatewayInfo?.firmwareVersion) {
    return <>{global.NOT_AVAILABLE}</>;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
      }}
    >
      {gatewayInfo.firmwareVersion}
      {!isLatestFirmware && (
        <Tooltip
          content={<div>Not the latest release version</div>}
          arrowDirection="top"
          underline={false}
        >
          <div style={{ paddingTop: '2px' }}>
            <WarningIcon size={14} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const determineOfflineGateways = (
  gateways: (Gateway | ControlBoard)[] | undefined,
  gatewayEntity: string
) => {
  if (!isDefined(gateways)) {
    return [];
  }

  const offlineFilteredGateways = gateways?.filter(g => {
    return (
      g?.['_entity'] === gatewayEntity && !g.gatewayOnlineStatus?.onlineState
    );
  });

  return offlineFilteredGateways;
};
