import * as R from 'ramda';
import { CircuitBreakerInPanel } from '@energybox/react-ui-library/dist/types';
import { EditableFields } from '../reducers/circuit_breakers';
const apiBase = '/api/v1/breakers';

export enum Actions {
  GET_CIRCUIT_BREAKERS_SUCCESS = '@circuit_breakers/GET_CIRCUIT_BREAKERS_SUCCESS',
  GET_CIRCUIT_BREAKERS_ERROR = '@circuit_breakers/GET_CIRCUIT_BREAKERS_ERROR',
  GET_CIRCUIT_BREAKERS_LOADING = '@circuit_breakers/GET_CIRCUIT_BREAKERS_LOADING',

  GET_CIRCUIT_BREAKER_SUCCESS = '@circuit_breakers/GET_CIRCUIT_BREAKER_SUCCESS',
  GET_CIRCUIT_BREAKER_ERROR = '@circuit_breakers/GET_CIRCUIT_BREAKER_ERROR',
  GET_CIRCUIT_BREAKER_LOADING = '@circuit_breakers/GET_CIRCUIT_BREAKER_LOADING',

  PATCH_CIRCUIT_BREAKER_LOADING = '@circuit_breakers/PATCH_CIRCUIT_BREAKER_LOADING',
  PATCH_CIRCUIT_BREAKER_ERROR = '@circuit_breakers/PATCH_CIRCUIT_BREAKER_ERROR',
  PATCH_CIRCUIT_BREAKER_SUCCESS = '@circuit_breakers/PATCH_CIRCUIT_BREAKER_SUCCESS',

  DELETE_CIRCUIT_BREAKER_SUCCESS = '@circuit_breakers/DELETE_CIRCUIT_BREAKER_SUCCESS',
  DELETE_CIRCUIT_BREAKER_ERROR = '@circuit_breakers/DELETE_CIRCUIT_BREAKER_ERROR',
  DELETE_CIRCUIT_BREAKER_LOADING = '@circuit_breakers/DELETE_CIRCUIT_BREAKER_LOADING',

  TOGGLE_NEW_CIRCUIT_BREAKER_MODAL = '@circuit_breakers/TOGGLE_NEW_CIRCUIT_BREAKER_MODAL',

  UPDATE_FIELD = '@circuit_breakers/UPDATE_FIELD',
  RESET_EDIT_CIRCUIT_BREAKER = '@circuit_breakers/RESET_EDIT_CIRCUIT_BREAKER',

  INSTALL_CIRCUIT_BREAKER_LOADING = '@circuit_breakers/INSTALL_CIRCUIT_BREAKER_LOADING',
  INSTALL_CIRCUIT_BREAKER_ERROR = '@circuit_breakers/INSTALL_CIRCUIT_BREAKER_ERROR',
  INSTALL_CIRCUIT_BREAKER_SUCCESS = '@circuit_breakers/INSTALL_CIRCUIT_BREAKER_SUCCESS',

  TOGGLE_DELETE_CIRCUIT_BREAKER_MODAL = '@circuit_breakers/TOGGLE_DELETE_CIRCUIT_BREAKER_MODAL',
  PRIME_FOR_DELETE = '@circuit_breakers/PRIME_FOR_DELETE',
  UNSTAGE_DELETE = '@circuit_breakers/UNSTAGE_DELETE',

  DISPLAY_FORM_ERRORS = '@circuit_breakers/DISPLAY_FORM_ERRORS',

  MAP_BREAKERS_IN_PANEL_TO_BREAKERSTORE = '@circuit_breakers/MAP_BREAKERS_IN_PANEL_TO_BREAKERSTORE',
  MOVE_BREAKER_IN_PANEL_LOADING = '@circuit_breakers/MOVE_BREAKER_IN_PANEL_LOADING',
  MOVE_BREAKER_IN_PANEL_SUCCESS = '@circuit_breakers/MOVE_BREAKER_IN_PANEL_SUCCESS',
  MOVE_BREAKER_IN_PANEL_ERROR = '@circuit_breakers/MOVE_BREAKER_IN_PANEL_ERROR',

  SELECT_NEWLY_CREATED_EQUIPMENT = '@circuit_breakers/SELECT_NEWLY_CREATED_EQUIPMENT',
}

export type GetCircuitBreakerParams = {
  distributionPanelId: number;
  equipmentIds?: number[];
  ids?: string[];
  siblings?: boolean;
  types?: string[];
};

const createQueryString = (
  params: GetCircuitBreakerParams = { distributionPanelId: -1 }
) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getCircuitBreaker = (id: number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_CIRCUIT_BREAKER_SUCCESS,
  error: Actions.GET_CIRCUIT_BREAKER_ERROR,
  loading: Actions.GET_CIRCUIT_BREAKER_LOADING,
});

export const getCircuitBreakers = (
  params: GetCircuitBreakerParams = { distributionPanelId: -1 }
) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString(params)}`,
  success: Actions.GET_CIRCUIT_BREAKERS_SUCCESS,
  error: Actions.GET_CIRCUIT_BREAKERS_ERROR,
  loading: Actions.GET_CIRCUIT_BREAKERS_LOADING,
});

export const patch = (
  panelId: number,
  breakerId: number,
  payload?: EditableFields
) => (dispatch, getState) => {
  const acceptedPatchBreakerFields = [
    'id',
    'title',
    'description',
    'equipmentId',
    'rating',
    'breakerColumn',
    'breakerSlot',
    'siteTotal',
    'subpanel',
  ];

  dispatch({
    type: 'API_PATCH',
    path: `${apiBase}/${breakerId}`,
    payload: payload
      ? payload
      : R.pick(
          acceptedPatchBreakerFields,
          getState().circuitBreakers.editById[breakerId].fields
        ),
    loading: {
      type: Actions.PATCH_CIRCUIT_BREAKER_LOADING,
      panelId,
      breakerId,
    },
    success: {
      type: Actions.PATCH_CIRCUIT_BREAKER_SUCCESS,
      panelId,
      breakerId,
    },
    error: { type: Actions.PATCH_CIRCUIT_BREAKER_ERROR, panelId, breakerId },
  });
};

export const destroy = (panelId: number, breakerId: number) => ({
  type: 'API_DELETE',
  path: `${apiBase}/${breakerId}`,
  loading: { type: Actions.DELETE_CIRCUIT_BREAKER_LOADING, panelId, breakerId },
  success: { type: Actions.DELETE_CIRCUIT_BREAKER_SUCCESS, panelId, breakerId },
  error: { type: Actions.DELETE_CIRCUIT_BREAKER_ERROR, panelId, breakerId },
});

export const reset = (id: number) => ({
  type: Actions.RESET_EDIT_CIRCUIT_BREAKER,
  id,
});

export const showNewCircuitBreakerModal = () => ({
  type: Actions.TOGGLE_NEW_CIRCUIT_BREAKER_MODAL,
  value: true,
});

export const hideNewCircuitBreakerModal = () => ({
  type: Actions.TOGGLE_NEW_CIRCUIT_BREAKER_MODAL,
  value: false,
});

export const displayFormErrors = (id: string) => ({
  type: Actions.DISPLAY_FORM_ERRORS,
  value: true,
  id,
});

export const showDeleteCircuitBreakerModal = () => ({
  type: Actions.TOGGLE_DELETE_CIRCUIT_BREAKER_MODAL,
  value: true,
});

export const hideDeleteCircuitBreakerModal = () => ({
  type: Actions.TOGGLE_DELETE_CIRCUIT_BREAKER_MODAL,
  value: false,
});

export const primeForDelete = (id: number) => ({
  type: Actions.PRIME_FOR_DELETE,
  id,
});

export const unstageDelete = () => ({
  type: Actions.UNSTAGE_DELETE,
});

export const updateField = (id: string, field: string, value: any) => ({
  type: Actions.UPDATE_FIELD,
  id,
  field,
  value,
});

export const mapBreakersInPanelToBreakerStore = (
  breakersInPanel: CircuitBreakerInPanel[]
) => ({
  type: Actions.MAP_BREAKERS_IN_PANEL_TO_BREAKERSTORE,
  data: breakersInPanel,
});
