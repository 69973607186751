import {
  Button,
  Card,
  CardActions,
  Label,
  Loader,
} from '@energybox/react-ui-library/dist/components';
import { hasKeys, isDefined } from '@energybox/react-ui-library/dist/utils';
import { Site } from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as SiteActions,
  displayFormErrors,
  getSite,
  patch,
  reset,
  updateField,
} from '../../actions/sites';
import SelectEnergyCalculationMode from '../../containers/Selects/SelectEnergyCalculationMode';
import { ApplicationState } from '../../reducers';
import { EditSite } from '../../reducers/sites';
import { PropertyToLabel } from '../../types/global';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import DeleteDistributionPanelModal from '../DistributionPanels/DeleteDistributionPanelModal';
import DistributionPanelCardList from '../DistributionPanels/DistributionPanelCardList';
import NewDistributionPanelModal from '../DistributionPanels/NewDistributionPanelModal';
import SiteEnergyChart from '../../components/SiteEnergyChart';
import SiteTotalBreakersLiveReadingTable from '../DistributionPanels/SiteTotalBreakersLiveReadingTable';
import { Link } from 'react-router-dom';
import { Routes } from '../../routes';

type OwnProps = {
  id: string;
  siteTitle: string;
};

interface Props extends OwnProps {
  getSite: () => void;
  onChange: (field: string, value: any) => void;
  patch: () => void;
  onDelete: () => void;
  onCancel: () => void;
  displayFormErrors: () => void;
  editSite: EditSite | undefined;
  site: Site | undefined;
}

class ShowSitePageDistributionPanels extends React.Component<Props> {
  componentDidMount() {
    const { editSite, getSite } = this.props;
    if (!editSite) {
      getSite();
    }
  }

  onSave = () => {
    const { patch, editSite, displayFormErrors } = this.props;

    if (editSite && hasKeys(editSite.formErrors)) {
      displayFormErrors();
    } else {
      patch();
    }
  };

  renderMiniForm = () => {
    const { id, editSite, onChange, onCancel } = this.props;
    if (!editSite) return null;
    const { formErrors, formErrorsVisible } = editSite;
    const { energyCalculationMode } = editSite.fields;

    const hasSiteFormError =
      isDefined(formErrors) && Object.keys(formErrors).length > 0;

    return (
      <Card>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '10rem 10rem',
            padding: '0.75rem 1rem',
          }}
        >
          <Label>{PropertyToLabel.energyCalculationMode}</Label>
          <div>
            <SelectEnergyCalculationMode
              value={energyCalculationMode}
              onSelect={id => onChange('energyCalculationMode', id)}
              error={false}
            />
          </div>
        </div>

        {hasSiteFormError && formErrorsVisible && (
          <div
            style={{
              marginTop: '1rem',
              marginLeft: '1rem',
              color: 'var(--pink-base)',
              fontSize: '0.75rem',
            }}
          >
            <span>Please complete the </span>
            <Link to={`${Routes.SITES}/${id}/general`}>
              Site General Information and Address
            </Link>
            <span> form first</span>
          </div>
        )}

        <div>
          {renderAPIerror(editSite.apiError, SiteActions.PATCH_SITE_ERROR)}
        </div>

        {editSite.isChanged && (
          <CardActions>
            <Button variant="text" onClick={onCancel} children="Cancel" />

            <Button disabled={editSite.isLoading} onClick={this.onSave}>
              {editSite.isLoading ? (
                <Loader size={16} variant="secondary" />
              ) : (
                'Save changes'
              )}
            </Button>
          </CardActions>
        )}
      </Card>
    );
  };

  render() {
    const { id, siteTitle, site } = this.props;

    return (
      <>
        <DistributionPanelCardList siteId={id} siteTitle={siteTitle} />

        <div
          style={{
            width: '100%',
            height: '0.1rem',
            margin: '2rem 0',
            backgroundColor: 'var(--ambient-basePlus90)',
          }}
        />

        {this.renderMiniForm()}
        {site && <SiteEnergyChart site={site} />}
        <SiteTotalBreakersLiveReadingTable siteId={id} />

        <NewDistributionPanelModal lockSiteId={parseInt(id)} />
        <DeleteDistributionPanelModal />
      </>
    );
  }
}

const mapStateToProps = ({ sites }: ApplicationState, { id }: OwnProps) => ({
  editSite: sites.editById[parseInt(id)],
  site: sites.sitesById[id],
});

const mapDispatchToProps = (dispatch: any, { id }: OwnProps) => ({
  getSite: () => dispatch(getSite(id)),
  onChange: (f: string, v: string) => dispatch(updateField(id, f, v)),
  patch: () => dispatch(patch(id)),
  onCancel: () => dispatch(reset(id)),
  displayFormErrors: () => dispatch(displayFormErrors(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowSitePageDistributionPanels);
