import {
  CircuitBreaker,
  EnergySensor,
  GenericErrors,
} from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import {
  Button,
  Label,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import pathOr from 'ramda/src/pathOr';

import React from 'react';
import { connect } from 'react-redux';
import {
  addEnergyDeviceSensorToCircuitBreaker,
  addEnergyDeviceSensorToPanelMainBreaker,
  displayEnergyDeviceSensorFormErrors,
  hideNewEnergyDeviceSensorModal,
  updateEnergyDeviceSensorField,
} from '../../actions/energy_devices';
import { addEnergyProSensorToPanelMainBreaker } from '../../actions/energy_pros';
import EditEnergyDeviceSensorForm from '../../components/EditEnergyDeviceSensorForm';
import { ApplicationState } from '../../reducers';
import { EditableSensorFields } from '../../reducers/energy_devices';
import { ApiError } from '../../utils/apiErrorFeedback';
import { SelectedEnergyDevice } from '../DistributionPanels/ShowDistributionPanelPage/ShowDistributionPanelPage';

interface OwnProps {
  panelId: string | number;
  breakerId?: string | number;
  energyDeviceId: string | number;
  sensors: EnergySensor[];
  resetSelectedEnergyDevice?: () => void;
  predeterminedData?: SelectedEnergyDevice;
  refetchEnergyPro?: boolean;
  energyProId?: number;
}

interface Props extends OwnProps {
  onChange: (field: string, value: string | number) => void;
  addEnergyDeviceSensorToCircuitBreaker: () => void;
  addEnergyDeviceSensorToPanelMainBreaker: () => void;
  addEnergyProSensorToPanelMainBreaker: () => void;
  onClose: () => void;
  displayFormErrors: () => void;
  fields: EditableSensorFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
  circuitBreakers: CircuitBreaker[];
  deviceType: string;
}

function NewEnergyDeviceSensorModal(props: Props) {
  const {
    fields,
    onChange,
    onClose,
    addEnergyDeviceSensorToCircuitBreaker,
    addEnergyDeviceSensorToPanelMainBreaker,
    addEnergyProSensorToPanelMainBreaker,
    isLoading,
    formErrors,
    formErrorsVisible,
    displayFormErrors,
    apiError,
    circuitBreakers,
    deviceType,
    sensors,
    resetSelectedEnergyDevice,
    predeterminedData,
    panelId,
  } = props;

  React.useEffect(() => {
    return () => {
      resetSelectedEnergyDevice && resetSelectedEnergyDevice();
    };
  }, [resetSelectedEnergyDevice]);

  const EnergyProLabel = 'Energy Pro';
  const energyDeviceType = deviceType || EnergyProLabel;
  const isMainBreaker = fields?.breakerId === -1 && fields?.breakerPole !== -1;

  const onEnergyDeviceSensorCreate = () => {
    //there are three possibilities:
    //1. energyPro sensor connects to panel main breaker
    //2. energyBar/Spider/Flex sensor connects to panel main breaker
    //3. energyBar/Spider/Flex sensor connects to normal circuit breaker
    const determineSensorCreateAction = () => {
      if (energyDeviceType === EnergyProLabel) {
        addEnergyProSensorToPanelMainBreaker();
      } else {
        if (isMainBreaker) {
          addEnergyDeviceSensorToPanelMainBreaker();
        } else {
          addEnergyDeviceSensorToCircuitBreaker();
        }
      }
    };

    if (hasKeys(formErrors)) {
      displayFormErrors();
    } else {
      determineSensorCreateAction();
    }
  };

  const actions = (
    <>
      <Button
        variant="text"
        onClick={() => {
          resetSelectedEnergyDevice && resetSelectedEnergyDevice();
          onClose();
        }}
      >
        Cancel
      </Button>

      <Button disabled={isLoading} onClick={onEnergyDeviceSensorCreate}>
        Create
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalTitle> New {energyDeviceType} sensor</ModalTitle>
      <ModalContent>
        <EditEnergyDeviceSensorForm
          isNew
          fields={fields}
          onChange={onChange}
          formErrorsVisible={formErrorsVisible}
          formErrors={formErrors}
          apiError={apiError}
          circuitBreakers={circuitBreakers}
          deviceType={energyDeviceType}
          occupiedPorts={sensors.map(s => s.port)}
          predeterminedData={predeterminedData}
          isMainBreaker={isMainBreaker}
          panelId={panelId}
        />
      </ModalContent>
      <ModalContent>
        <Label>* Mandatory fields</Label>
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = (
  { energyDevices, circuitBreakers, distributionPanels }: ApplicationState,
  { energyDeviceId, panelId }: OwnProps
) => {
  return {
    ...(energyDevices.editEnergyDeviceSensorsByEnergyDeviceId[energyDeviceId] &&
      energyDevices.editEnergyDeviceSensorsByEnergyDeviceId[energyDeviceId][
        'new'
      ]),
    circuitBreakers: pathOr(
      [],
      [panelId, 'breakers'],
      distributionPanels.distributionPanelsById
    ).map(breaker => circuitBreakers.circuitBreakersById[breaker.breaker.id]),
    deviceType:
      energyDevices.energyDevicesById[energyDeviceId] &&
      energyDevices.energyDevicesById[energyDeviceId].resourceType,
  };
};

const mapDispatchToProps = (
  dispatch,
  { energyDeviceId, refetchEnergyPro, energyProId }: OwnProps
) => ({
  onClose: () =>
    dispatch(hideNewEnergyDeviceSensorModal(energyDeviceId.toString())),
  onChange: (field: string, value: string | number) =>
    dispatch(
      updateEnergyDeviceSensorField(
        'new',
        field,
        value,
        parseInt(energyDeviceId.toString())
      )
    ),
  addEnergyDeviceSensorToCircuitBreaker: () =>
    dispatch(
      addEnergyDeviceSensorToCircuitBreaker(
        energyDeviceId.toString(),
        energyProId
      )
    ),
  addEnergyDeviceSensorToPanelMainBreaker: () =>
    dispatch(
      addEnergyDeviceSensorToPanelMainBreaker(
        energyDeviceId.toString(),
        energyProId
      )
    ),
  addEnergyProSensorToPanelMainBreaker: () =>
    dispatch(
      addEnergyProSensorToPanelMainBreaker(
        energyDeviceId.toString(),
        refetchEnergyPro
      )
    ),
  displayFormErrors: () =>
    dispatch(
      displayEnergyDeviceSensorFormErrors(
        'new',
        parseInt(energyDeviceId.toString())
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewEnergyDeviceSensorModal);
