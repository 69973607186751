import {
  Button,
  ExtraShortShortSkeletonCell,
  LongExtraLongSkeletonCell,
  MediaElement,
  MediumSkeletonCell,
  SearchBox,
  ShortSkeletonCell,
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  Sentinel,
  sentinelTypeToSentinelTargetType,
  SortDirection,
  OpacityIndex,
  SentinelTarget,
  MutedTarget,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  SORT_IGNORED_VALUES,
  hasSubstr,
  getSentinelConditionText,
  getSentinelDelayText,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import {
  VolumeOff as VolumeOffIcon,
  VolumeUp as VolumeUpIcon,
} from '@energybox/react-ui-library/dist/icons';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { openModal } from '../../actions/modal';
import {
  deleteSentinel,
  muteSentinel,
  resumeSentinel,
} from '../../actions/sentinels';
import MuteModal from '../../components/Modals/MuteModal/MuteModal';
import { Routes } from '../../routes';
import { OpenModal } from '../../types/modal';
import { checkCommonPlural } from '../../util';
import {
  countMutedTargets,
  countRecipients,
  countTargets,
  deleteSentinelModal,
  SentinelTypeIcon,
  channel,
} from '../../utils/sentinel';
import { toMuteISOString } from '../../utils/string';
import styles from './SentinelsTable.module.css';
import { useCurrentUser } from '../../hooks/useAppDetails';
import DurationPicker from '../../components/Modals/MuteModal/DurationPicker/DurationPicker';
import { isFirstTimeCopy } from '../../actions/equipment';

import theme from '../../theme';
import { TableWrapper } from '../../components/ui/Table';

import { Placeholder } from '../../types/global';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { useUrlState } from '../../hooks/utils';

interface Props {
  dataIsLoading: boolean;
  sentinels: Sentinel[];
  columns: SentinelTableColumns[];
  hasSearch?: boolean;
  secondaryAction?: any | React.Component;
  redirect?: string;
  renderLimit?: number;
  currentSiteId?: number | string;
  currentTargetId?: number | string;
  ianaTimeZoneCode?: string;
  selectedTargetId?: number | string;
  mutedMappingBySentinelId?: {
    [sentinelId: string]: {
      [targetId: string]: MutedTarget;
    };
  };
  muteTarget?: (sentinelId: number, mutedTarget: MutedTarget) => void;
  unmuteTarget?: (sentinelId: number, mutedTarget: MutedTarget) => void;
  viewportType: ViewportTypes.MOBILE;
}

export const SentinelsTable = (props: Props) => {
  const {
    dataIsLoading,
    sentinels,
    columns,
    secondaryAction,
    hasSearch,
    renderLimit,
    currentSiteId,
    currentTargetId,
    ianaTimeZoneCode,
    selectedTargetId,
    mutedMappingBySentinelId,
    muteTarget,
    unmuteTarget,
    viewportType,
  } = props;

  const history = useHistory();
  const isMobile = viewportType === ViewportTypes.MOBILE;
  const currentUser = useCurrentUser();
  const didMount = useRef(false);

  const dispatch = useDispatch();

  const [sentinelsLoadingGuard, setSentinelsLoadingGuard] = useState(true);
  const [muteModalSentinel, setMuteModalSentinel] = useState<
    Sentinel | undefined
  >(undefined);

  const [savedQuery, updateSavedQuery] = useUrlState<string>('query', '');
  const [savedPage, updateSavedPage] = useUrlState<number>('page', 1);

  useEffect(() => {
    setTimeout(() => {
      setSentinelsLoadingGuard(false);
    }, 750);
  }, []);

  const handleSearchChange = (query: string) => {
    updateSavedQuery(query);
  };

  const pagination = (pageCount: number) => {
    updateSavedPage(pageCount);
  };

  const filteredSentinels =
    savedQuery && savedQuery.length >= 3
      ? sentinels.filter((sentinel: Sentinel) =>
          hasSubstr(`${sentinel.title}${sentinel.description}`, savedQuery)
        )
      : sentinels;

  const isLoading = dataIsLoading || sentinelsLoadingGuard;

  const renderedColumns: Columns<Sentinel>[] = useMemo(() => {
    const columnsMapping = getColumnsMapping({
      history,
      currentUser,
      dispatch,
      setMuteModalSentinel,
      ianaTimeZoneCode,
      selectedTargetId,
      muteTarget,
      unmuteTarget,
      mutedMappingBySentinelId,
    });
    return columns.map(column => columnsMapping[column]);
  }, [
    currentUser,
    dispatch,
    setMuteModalSentinel,
    ianaTimeZoneCode,
    selectedTargetId,
    muteTarget,
    unmuteTarget,
    mutedMappingBySentinelId,
  ]);

  useEffect(() => {
    // we have to remove the duplicateSentinelkey from local storage
    localStorage.removeItem('duplicateSentinelKey');
    dispatch(isFirstTimeCopy(0));
  }, []);

  return (
    <>
      <DynamicContentWrapper>
        <PageContentHeader header="All Sentinels">
          {secondaryAction ? secondaryAction : ''}
          {hasSearch && (
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={handleSearchChange}
              query={savedQuery}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={filteredSentinels.length === 0}
            />
          )}
        </PageContentHeader>
        <TableWrapper
          header={checkCommonPlural('Sentinel', filteredSentinels.length)}
        >
          <Table
            listView
            columns={renderedColumns}
            data={filteredSentinels}
            dataIsLoading={isLoading}
            renderLimit={renderLimit}
            pagination={pagination}
            pageNumber={filteredSentinels.length > 5 ? savedPage : 1}
          />
        </TableWrapper>
      </DynamicContentWrapper>
      {muteModalSentinel && (
        <MuteModal
          sentinel={muteModalSentinel}
          onClose={() => setMuteModalSentinel(undefined)}
          targetTypes={sentinelTypeToSentinelTargetType(
            muteModalSentinel.sentinelType
          )}
          currentSiteId={currentSiteId}
          currentTargetId={currentTargetId}
        />
      )}
    </>
  );
};

export enum SentinelTableColumns {
  CONFIG = 'config',
  CONDITION = 'condition',
  RECIPIENTS = 'recipients',
  TARGETS = 'targets',
  MUTED_TARGETS = 'mutedTargets',
  RESUME_CONFIG = 'resumeConfig',
  MUTE = 'mute',
  MUTE_CURENT_TARRGET = 'muteCurrentTarget',
  DUBLICATE_SENTINEL = '',
  CHANNEL_TYPE = 'channelType',
}

const getColumnsMapping = ({
  history,
  dispatch,
  currentUser,
  setMuteModalSentinel,
  ianaTimeZoneCode,
  selectedTargetId,
  muteTarget,
  unmuteTarget,
  mutedMappingBySentinelId,
}): {
  [column: string]: Columns<Sentinel>;
} => {
  return {
    [SentinelTableColumns.CONFIG]: {
      width: '30%',
      header: 'Configuration',
      cellContent: (sentinel: Sentinel) => {
        return (
          <Link to={`${Routes.SENTINELS}/${sentinel.id}/condition`}>
            <MediaElement
              title={sentinel.title}
              description={sentinel.description}
              icon={<SentinelTypeIcon type={sentinel.sentinelType} size={24} />}
            />
          </Link>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a: Sentinel, b: Sentinel, sortDirection: SortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'title',
        ]);
      },
    },
    [SentinelTableColumns.CONDITION]: {
      width: '30%',
      header: 'Condition',
      cellContent: (sentinel: Sentinel) => {
        return (
          <>
            <div>{getSentinelConditionText(sentinel, currentUser)}</div>
            <div>{getSentinelDelayText(sentinel)}</div>
          </>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    [SentinelTableColumns.RECIPIENTS]: {
      width: '10%',
      header: 'Recipients',
      defaultSortDirection: SortDirection.DESC,
      cellContent: (sentinel: Sentinel) => <>{countRecipients(sentinel)}</>,
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a: Sentinel, b: Sentinel, sortDirection: SortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          countRecipients
        );
      },
    },
    [SentinelTableColumns.TARGETS]: {
      width: '10%',
      header: 'Targeted Devices',
      defaultSortDirection: SortDirection.DESC,
      cellContent: (sentinel: Sentinel) => (
        <>{(sentinel.targets || []).length}</>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a: Sentinel, b: Sentinel, sortDirection: SortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          countTargets
        );
      },
    },
    [SentinelTableColumns.MUTED_TARGETS]: {
      width: '10%',
      header: 'Muted Devices',
      cellContent: (sentinel: Sentinel) => (
        <>
          {sentinel.muted
            ? sentinel.targets.length
            : (sentinel.mutedTargets || []).length}
        </>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a: Sentinel, b: Sentinel, sortDirection: SortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          countMutedTargets
        );
      },
    },
    [SentinelTableColumns.CHANNEL_TYPE]: {
      width: '10%',
      header: 'Channels',
      defaultSortDirection: SortDirection.DESC,
      cellContent: (sentinel: Sentinel) => <>{channel(sentinel)}</>,
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ExtraShortShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a: Sentinel, b: Sentinel, sortDirection: SortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          channel
        );
      },
    },
    [SentinelTableColumns.RESUME_CONFIG]: {
      // width: '10%',
      width: '20%',
      header: '',
      cellContent: (sentinel: Sentinel) => (
        <>
          {sentinel.valid ? (
            ''
          ) : (
            <Button
              variant="outlined"
              onClick={() => history.push(`${Routes.SENTINELS}/${sentinel.id}`)}
            >
              Finish configuration
            </Button>
          )}
        </>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediumSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    [SentinelTableColumns.MUTE]: {
      width: '10%',
      header: 'Mute',
      align: 'right',
      cellContent: (sentinel: Sentinel) => (
        <div className={styles.dropdownMenu}>
          <div className={styles.dropdownMenuButton}>
            <MenuDropdown
              icon={
                sentinel.muted ? (
                  <VolumeOffIcon
                    style={{ color: 'var(--ambient-base)' }}
                    size={16}
                  />
                ) : (
                  <VolumeUpIcon size={16} />
                )
              }
            >
              <MenuDropdownItem
                onSelect={() => {
                  if (sentinel.muted) {
                    // unmute request
                    dispatch(resumeSentinel(sentinel.id));
                  } else {
                    // mute request
                    dispatch(
                      muteSentinel(sentinel.id, {
                        mutedTargets: [],
                        sentinelMutedFrom: toMuteISOString(new Date()),
                      })
                    );
                  }
                }}
              >
                {sentinel.muted ? 'Unmute Sentinel' : 'Mute Sentinel'}
              </MenuDropdownItem>
              <MenuDropdownItem
                disabled={sentinel.muted}
                onSelect={() => setMuteModalSentinel(sentinel)}
              >
                Custom Mute
              </MenuDropdownItem>
              <MenuDropdownItem
                isRed
                onSelect={() =>
                  deleteSentinelModal(
                    (modalConfig: OpenModal) =>
                      dispatch(openModal(modalConfig)),
                    () => dispatch(deleteSentinel(sentinel.id)),
                    sentinel.id,
                    sentinel.title
                  )
                }
              >
                Delete
              </MenuDropdownItem>
            </MenuDropdown>
          </div>
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
    },
    // We have to make a select box
    [SentinelTableColumns.DUBLICATE_SENTINEL]: {
      width: '10%',
      header: '',
      align: 'right',
      cellContent: (sentinel: Sentinel) => {
        return (
          <div className={styles.dropdownMenu}>
            <div className={styles.dropdownMenuButton}>
              <MenuDropdown>
                {/* <FeatureFlag> */}
                <MenuDropdownItem
                  onSelect={() => {
                    //  set a(copy of) key for make duplicate sentinel
                    localStorage.setItem('duplicateSentinelKey', 'Copy Of');
                    history.push({
                      pathname: `${Routes.SENTINELS}/${sentinel.id}/type`,
                    });
                  }}
                >
                  Make a Copy
                </MenuDropdownItem>
                {/* </FeatureFlag> */}
                <MenuDropdownItem
                  isRed
                  onSelect={() =>
                    deleteSentinelModal(
                      (modalConfig: OpenModal) =>
                        dispatch(openModal(modalConfig)),
                      () => dispatch(deleteSentinel(sentinel.id)),
                      sentinel.id,
                      sentinel.title
                    )
                  }
                >
                  Delete
                </MenuDropdownItem>
              </MenuDropdown>
            </div>
          </div>
        );
      },
    },

    [SentinelTableColumns.MUTE_CURENT_TARRGET]: {
      width: '15%',
      header: 'Mute Notification',
      cellContent: (sentinel: Sentinel) => {
        const mutedMappingByTargetId = mutedMappingBySentinelId[sentinel.id];
        const { targets } = sentinel;
        if (
          !ianaTimeZoneCode ||
          !selectedTargetId ||
          mutedMappingByTargetId === undefined
        ) {
          return null;
        }

        const targetsById: {
          [targetId: string]: SentinelTarget;
        } = mapArrayToObject(targets || [], 'targetId');

        const mutedTarget = mutedMappingByTargetId[selectedTargetId];

        return (
          <DurationPicker
            disabled={sentinel.muted}
            sentinelId={sentinel.id}
            ianaTimeZoneCode={ianaTimeZoneCode}
            target={targetsById[selectedTargetId]}
            mutedTarget={mutedTarget}
            muteTarget={muteTarget}
            unmuteTarget={unmuteTarget}
          />
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
    },
  };
};

export default withViewportType(SentinelsTable);
