import {
  EnergyDevice,
  EnergyDeviceType,
  EnergySensor,
  GenericErrors,
  EnergyDeviceFromApi,
  EnergyPro,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/energy_devices';
import { Actions as EnergyProActions } from '../actions/energy_pros';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { formValidationErrors } from '../utils/formValidation';

export interface EditableFields {
  description: string;
  title: string;
  model: EnergyDeviceType;
  energyProId: number;
  bus: number;
}
export const editableFieldKeys = [
  'description',
  'title',
  'model',
  'energyProId',
  'bus',
];

export const editableFields = (energyDevice: object) =>
  R.pick(editableFieldKeys, energyDevice);

export const newEnergyDeviceFields = {
  title: '',
  description: '',
  model: EnergyDeviceType.UNASSIGNED,
  showDeleteEnergyDeviceModal: false,
  energyProId: -1,
  bus: -1,
};

export const newEnergyDevice = {
  isLoading: false,
  isChanged: false,
  fields: newEnergyDeviceFields,
  formErrors: formValidationErrors('newEnergyDevice', newEnergyDeviceFields),
  formErrorsVisible: false,
  apiError: {},
};

export type EditEnergyDevice = {
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  apiError: ApiError;
  showDeleteEnergyDeviceModal: boolean;
};

export type EnergyDevicesById = {
  [id: string]: EnergyDevice;
};

export interface EnergyDevices {
  energyDevicesById: EnergyDevicesById;
  editById: EditById;
  editEnergyDeviceSensorsByEnergyDeviceId: EditEnergyDeviceSensorsByEnergyDeviceId;
  apiError: ApiError;
  showNewEnergyDeviceModal: boolean;
  showNewEnergyDeviceSensorModal: boolean;
  showRemoveAllEnergyDeviceSensorsModal: boolean;
}

export const initialState = {
  energyDevicesById: {},
  editById: {},
  apiError: {},
  showNewEnergyDeviceModal: false,
  editEnergyDeviceSensorsByEnergyDeviceId: {},
  showNewEnergyDeviceSensorModal: false,
  showRemoveAllEnergyDeviceSensorsModal: false,
};

export type EditById = {
  [id: string]: EditEnergyDevice;
};

export type EditEnergyDeviceSensorsByEnergyDeviceId = {
  [id: string]: EditEnergyDeviceSensors;
};

export type EditEnergyDeviceSensors = {
  [id: string]: EditEnergyDeviceSensor;
};

export type EditEnergyDeviceSensor = {
  port?: number;
  isLoading: boolean;
  isChanged: boolean;
  fields: EditableSensorFields;
  formErrors: GenericErrors;
  formErrorsVisible: boolean;
  showDeleteEnergyDeviceSensorModal: boolean;
  apiError: ApiError;
};

export interface EditableSensorFields {
  breakerId: number;
  breakerPole: number;
  ct: string;
  energyDevicePort: number;
  reversePolarity: boolean;
  phase: number;
}

export const editableSensorFieldKeys = [
  'breakerId',
  'breakerPole',
  'ct',
  'energyDevicePort',
  'reversePolarity',
  'phase',
];

export const editableSensorFields = (energyDeviceSensor: object) =>
  R.pick(editableSensorFieldKeys, energyDeviceSensor);

export const newEnergyDeviceSensorFields = {
  breakerId: -1,
  breakerPole: -1,
  ct: '',
  energyDevicePort: -1,
  reversePolarity: false,
  phase: -1,
};

export const newEnergyDeviceSensor = {
  isLoading: false,
  isChanged: false,
  fields: newEnergyDeviceSensorFields,
  formErrors: formValidationErrors(
    'energyDeviceSensor',
    newEnergyDeviceSensorFields
  ),
  formErrorsVisible: false,
  apiError: {},
};

export const energyDeviceSensorFromApiResponse = (data: any) => ({
  breakerId: data.breakerId,
  breakerPole: data.breakerPole,
  ct: data.ct,
  energyDevicePort: data.port,
  reversePolarity: data.reversePolarity,
  phase: data.phase,
});

export const energyDeviceFromApiResponse = (data: any) => ({
  resourceType: ResourceType[data._entity.toUpperCase()],
  id: data.id,
  sensors: data.sensors,
  title: data.title,
  description: data.description,
});

const mapGetEnergyDeviceToId = (apiResponse: EnergyDeviceFromApi) => {
  const energyDevice = energyDeviceFromApiResponse(apiResponse);

  return [
    R.assocPath(['editById', String(energyDevice.id)], {
      isLoading: false,
      formErrorsVisible: false,
      fields: editableFields(energyDevice),
      apiError: {},
      isChanged: false,
      formErrors: {},
      showDeleteEnergyDeviceModal: false,
    }),
    R.assocPath(['energyDevicesById', String(energyDevice.id)], energyDevice),
  ];
};

const mapSensorsInEnergyDeviceToEditByEnergyDeviceId = (
  sensors: EnergySensor[]
) => {
  const editByEnergyDeviceIdFormat = (sensorInDevice: EnergySensor) => {
    const sensor = energyDeviceSensorFromApiResponse(sensorInDevice);
    return {
      port: sensorInDevice.port,
      fields: editableSensorFields(sensor),
      isLoading: false,
      formErrorsVisible: false,
      apiError: {},
    };
  };

  return mapArrayToObject(
    mapValues(sensors, editByEnergyDeviceIdFormat),
    'port'
  );
};

const deleteEnergyDeviceSensorsLinkedToDeletedBreaker = (
  energyDevicesById: EnergyDevicesById,
  deletedBreakerId: number
) => {
  const filterOutSensorsByDeletedBreakerId = (energyDevice: EnergyDevice) => {
    return {
      ...energyDevice,
      sensors: energyDevice.sensors.filter(
        sensor => sensor.breakerId !== deletedBreakerId
      ),
    };
  };

  return mapArrayToObject(
    mapValues(energyDevicesById, filterOutSensorsByDeletedBreakerId)
  );
};

const mapEnergyDeviceFromEnergyPros = (energyPros: EnergyPro[] | EnergyPro) => {
  const arrayOfEnergyDevices: EnergyDeviceFromApi[] = [];
  const recursivelyGetEnergyDevice = (
    energyDevice: EnergyDeviceFromApi | null
  ) => {
    if (!energyDevice) return;
    arrayOfEnergyDevices.push(energyDevice);
    if (energyDevice.busDevice !== null) {
      recursivelyGetEnergyDevice(energyDevice.busDevice);
    }
  };

  if (Array.isArray(energyPros)) {
    energyPros.forEach(energyPro => {
      recursivelyGetEnergyDevice(energyPro.bus1Device);
      recursivelyGetEnergyDevice(energyPro.bus2Device);
    });
  } else {
    recursivelyGetEnergyDevice(energyPros.bus1Device);
    recursivelyGetEnergyDevice(energyPros.bus2Device);
  }

  return R.flatten(
    arrayOfEnergyDevices.map(energyDevice =>
      mapGetEnergyDeviceToId(energyDevice)
    )
  );
};

const energyDevices = (state: EnergyDevices = initialState, action: any) => {
  switch (action.type) {
    case EnergyProActions.GET_ENERGY_PROS_BY_DISTRIBUTION_PANEL_ID_SUCCESS:
      const mappedEnergyDevices = mapEnergyDeviceFromEnergyPros(action.data);
      if (mappedEnergyDevices.length === 0) {
        return state;
      } else {
        return R.pipe(...mappedEnergyDevices)(state);
      }

    case Actions.REMOVE_SEVERAL_SENSORS_FROM_ENERGY_DEVICE_SUCCESS:
    case Actions.GET_ENERGY_DEVICE_SUCCESS:
    case Actions.PATCH_ENERGY_DEVICE_SUCCESS:
      return R.pipe(...mapGetEnergyDeviceToId(action.data))(state);

    case Actions.DISPLAY_FORM_ERRORS:
      return R.assocPath(
        ['editById', String(action.id), 'formErrorsVisible'],
        action.value,
        state
      );

    case Actions.UPDATE_FIELD:
      let updatedField = R.assoc(
        action.field,
        action.value,
        R.path(['editById', String(action.id), 'fields'], state)
      );
      return R.pipe(
        R.assocPath(['editById', String(action.id), 'fields'], updatedField),
        R.assocPath(['editById', String(action.id), 'isChanged'], true),
        R.assocPath(
          ['editById', String(action.id), 'formErrors'],
          formValidationErrors(
            action.id === 'new' ? 'newEnergyDevice' : 'editEnergyDevice',
            updatedField
          )
        )
      )(state);

    case Actions.PATCH_ENERGY_DEVICE_LOADING:
      return R.assocPath(
        ['editById', String(action.id), 'isLoading'],
        true,
        state
      );

    case Actions.PATCH_ENERGY_DEVICE_ERROR:
      return R.pipe(
        R.assocPath(
          ['editById', String(action.id), 'apiError'],
          storeAPIerror(action)
        ),
        R.assocPath(['editById', String(action.id), 'isLoading'], false)
      )(state);

    case Actions.RESET_EDIT_ENERGY_DEVICE:
      var fields = editableFields(
        R.path(['energyDevicesById', String(action.id)], state)
      );

      return R.pipe(
        R.assocPath(['editById', String(action.id), 'isChanged'], false),
        R.assocPath(['editById', String(action.id), 'fields'], fields),
        R.assocPath(['editById', action.id, 'formErrorsVisible'], false),
        R.assocPath(
          ['editById', String(action.id), 'formErrors'],
          formValidationErrors(
            action.id === 'new' ? 'newEnergyDevice' : 'editEnergyDevice',
            fields
          )
        )
      )(state);

    case Actions.TOGGLE_NEW_ENERGY_DEVICE_MODAL:
      return R.pipe(
        R.assocPath(['editById', 'new'], newEnergyDevice),
        R.assoc('showNewEnergyDeviceModal', action.value)
      )(state);

    case EnergyProActions.APPEND_ENERGY_DEVICE_LOADING:
      return R.assocPath(['editById', 'new', 'isLoading'], true, state);

    case EnergyProActions.GET_ENERGY_PRO_SUCCESS:
    case EnergyProActions.APPEND_ENERGY_DEVICE_SUCCESS:
      return R.pipe(
        R.assocPath(['editById', 'new', 'isLoading'], false),
        R.assoc('showNewEnergyDeviceModal', false),
        ...mapEnergyDeviceFromEnergyPros(action.data)
      )(state);

    case EnergyProActions.APPEND_ENERGY_DEVICE_ERROR:
      return R.pipe(
        R.assocPath(['editById', 'new', 'apiError'], storeAPIerror(action)),
        R.assocPath(['editById', 'new', 'isLoading'], false)
      )(state);

    case EnergyProActions.REMOVE_DEVICE_FROM_ENERGY_PRO_BUS_SUCCESS:
      return R.pipe(
        R.dissocPath(['editById', action.deviceId]),
        R.dissocPath(['energyDevicesById', action.deviceId])
      )(state);

    case EnergyProActions.REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_SUCCESS:
      return R.dissocPath(
        [
          'editEnergyDeviceSensorsByEnergyDeviceId',
          action.energyDeviceId,
          action.port,
        ],
        state
      );

    case Actions.TOGGLE_NEW_ENERGY_DEVICE_SENSOR_MODAL:
      return R.pipe(
        R.assocPath(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.energyDeviceId,
            'new',
          ],
          newEnergyDeviceSensor
        ),
        R.assoc('showNewEnergyDeviceSensorModal', action.value)
      )(state);

    case Actions.UPDATE_ENERGY_DEVICE_SENSOR_FIELD:
      let updatedSensorField = R.assoc(
        action.field,
        action.value,
        R.path(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.energyDeviceId,
            String(action.port),
            'fields',
          ],
          state
        )
      );

      return R.assocPath(
        [
          'editEnergyDeviceSensorsByEnergyDeviceId',
          action.energyDeviceId,
          String(action.port),
        ],
        {
          fields: updatedSensorField,
          isChanged: true,
          formErrors: formValidationErrors(
            'energyDeviceSensor',
            updatedSensorField
          ),
        },
        state
      );

    case Actions.RESET_ENERGY_DEVICE_SENSORS:
      const prevEnergyDeviceSensors = action.energyPro
        ? action.energyPro.sensors
        : R.pathOr(
            undefined,
            ['energyDevicesById', action.energyDeviceId, 'sensors'],
            state
          );

      if (prevEnergyDeviceSensors) {
        return R.assocPath(
          ['editEnergyDeviceSensorsByEnergyDeviceId', action.energyDeviceId],
          mapSensorsInEnergyDeviceToEditByEnergyDeviceId(
            prevEnergyDeviceSensors
          ),
          state
        );
      }
      return state;

    case Actions.DISPLAY_ENERGY_DEVICE_SENSOR_FORM_ERRORS:
      return R.assocPath(
        [
          'editEnergyDeviceSensorsByEnergyDeviceId',
          action.energyDeviceId,
          String(action.id),
          'formErrorsVisible',
        ],
        action.value,
        state
      );

    case Actions.EDIT_ENERGY_DEVICE_SENSOR_PORT_LOADING:
    case Actions.ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_LOADING:
    case Actions.ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_LOADING:
      return R.assocPath(
        [
          'editEnergyDeviceSensorsByEnergyDeviceId',
          action.energyDeviceId,
          action.port || 'new',
          'isLoading',
        ],
        true,
        state
      );

    case Actions.EDIT_ENERGY_DEVICE_SENSOR_PORT_ERROR:
    case Actions.ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_ERROR:
    case Actions.ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_ERROR:
      return R.pipe(
        R.assocPath(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.energyDeviceId,
            action.port || 'new',
            'apiError',
          ],
          storeAPIerror(action)
        ),
        R.assocPath(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.energyDeviceId,
            'new',
            'isLoading',
          ],
          false
        )
      )(state);

    case Actions.ADD_ENERGY_DEVICE_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS:
    case Actions.ADD_ENERGY_DEVICE_SENSOR_TO_BREAKER_SUCCESS:
    case Actions.EDIT_ENERGY_DEVICE_SENSOR_PORT_SUCCESS:
      return R.pipe(
        ...mapGetEnergyDeviceToId(action.data),
        R.assocPath(
          ['editEnergyDeviceSensorsByEnergyDeviceId', action.energyDeviceId],
          mapSensorsInEnergyDeviceToEditByEnergyDeviceId(action.data.sensors)
        ),
        R.assoc('showNewEnergyDeviceSensorModal', false)
      )(state);

    case EnergyProActions.ADD_ENERGY_PRO_SENSOR_TO_PANEL_MAIN_BREAKER_SUCCESS:
      return R.assoc('showNewEnergyDeviceSensorModal', false, state);

    case Actions.MAP_SENSORS_IN_ENERGY_DEVICE_TO_EDIT:
      return R.assocPath(
        ['editEnergyDeviceSensorsByEnergyDeviceId', action.energyDeviceId],
        mapSensorsInEnergyDeviceToEditByEnergyDeviceId(action.value),
        state
      );

    case Actions.TOGGLE_DELETE_ENERGY_DEVICE_MODAL: {
      return R.pipe(
        R.assocPath(
          ['editById', action.id, 'showDeleteEnergyDeviceModal'],
          action.value
        ),
        R.assoc('apiError', {})
      )(state);
    }

    case Actions.TOGGLE_REMOVE_ENERGY_DEVICE_SENSOR_MODAL:
      return R.pipe(
        R.assocPath(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.id,
            action.port,
            'showDeleteEnergyDeviceSensorModal',
          ],
          action.value
        ),
        R.assocPath(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.id,
            action.port,
            'apiError',
          ],
          {}
        )
      )(state);

    case EnergyProActions.REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_LOADING:
    case Actions.REMOVE_SENSOR_FROM_ENERGY_DEVICE_LOADING:
      return R.assocPath(
        [
          'editEnergyDeviceSensorsByEnergyDeviceId',
          action.energyDeviceId,
          action.port,
          'isLoading',
        ],
        true,
        state
      );

    case EnergyProActions.REMOVE_ENERGY_PRO_SENSOR_FROM_PANEL_MAIN_BREAKER_ERROR:
    case Actions.REMOVE_SENSOR_FROM_ENERGY_DEVICE_ERROR:
      return R.pipe(
        R.assocPath(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.energyDeviceId,
            action.port,
            'apiError',
          ],
          storeAPIerror(action)
        ),
        R.assocPath(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.energyDeviceId,
            action.port,
            'isLoading',
          ],
          false
        )
      )(state);

    case Actions.REMOVE_SENSOR_FROM_ENERGY_DEVICE_SUCCESS:
      return R.pipe(
        ...mapGetEnergyDeviceToId(action.data),
        R.assocPath(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.energyDeviceId,
            action.port,
            'isLoading',
          ],
          false
        ),
        R.assocPath(
          [
            'editEnergyDeviceSensorsByEnergyDeviceId',
            action.energyDeviceId,
            action.port,
            'showDeleteEnergyDeviceSensorModal',
          ],
          false
        )
      )(state);

    case Actions.TOGGLE_REMOVE_ALL_ENERGY_DEVICE_SENSORS_MODAL:
      return R.pipe(
        R.assoc('showRemoveAllEnergyDeviceSensorsModal', action.value),
        R.assoc('apiError', {})
      )(state);

    case Actions.REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_SUCCESS:
      return R.pipe(
        ...mapGetEnergyDeviceToId(action.data),
        R.assoc('showRemoveAllEnergyDeviceSensorsModal', false)
      )(state);

    case Actions.REMOVE_ALL_SENSORS_FROM_ENERGY_DEVICE_ERROR:
      return R.assoc('apiError', storeAPIerror(action), state);

    case Actions.DELETE_ENERGY_DEVICE_SENSORS_LINKED_TO_DELETED_CIRCUIT_BREAKER:
      return R.assoc(
        'energyDevicesById',
        deleteEnergyDeviceSensorsLinkedToDeletedBreaker(
          { ...state.energyDevicesById },
          action.breakerId
        ),
        state
      );

    default:
      return state;
  }
};

export default energyDevices;
