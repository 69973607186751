import { DistributionPanelType } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../reducers';

export const useDistributionPanelType = (
  panelId: number | string | undefined
) => {
  const panelType = useSelector<
    ApplicationState,
    DistributionPanelType | undefined
  >(({ distributionPanels }) => {
    if (isDefined(panelId)) {
      return distributionPanels.distributionPanelsById[panelId]?.type;
    }
    return undefined;
  });
  return panelType;
};
