import { ApplicationState } from '../reducers';
import {
  ReleaseType,
  FirmwareGatewayModel,
} from '@energybox/react-ui-library/dist/types';

export enum Actions {
  INIT = '@app/INIT',
  INIT_WITH_TOKEN = '@app/INIT_WITH_TOKEN',
  ACCESS_TOKEN_UPDATED = '@app/ACCESS_TOKEN_UPDATED',
  REDIRECT = '@app/REDIRECT',
  RESET_SOCKET_CONNECTION = '@app/RESET_SOCKET_CONNECTION',

  LOGIN_SUCCESS = '@app/LOGIN_SUCCESS',
  LOGIN_LOADING = '@app/LOGIN_LOADING',
  LOGIN_ERROR = '@app/LOGIN_ERROR',
  UPDATE_LOGIN_FIELD = '@app/UPDATE_LOGIN_FIELD',

  FORGOT_PASSWORD_SUCCESS = '@app/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_LOADING = '@app/FORGOT_PASSWORD_LOADING',
  FORGOT_PASSWORD_ERROR = '@app/FORGOT_PASSWORD_ERROR',
  UPDATE_FORGOT_PASSWORD_FIELD = '@app/UPDATE_FORGOT_PASSWORD_FIELD',

  RESET_PASSWORD_SUCCESS = '@app/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_LOADING = '@app/RESET_PASSWORD_LOADING',
  RESET_PASSWORD_ERROR = '@app/RESET_PASSWORD_ERROR',
  UPDATE_RESET_PASSWORD_FIELD = '@app/UPDATE_RESET_PASSWORD_FIELD',

  LOGOUT = '@app/LOGOUT',

  CURRENT_USER_SUCCESS = '@app/CURRENT_USER_SUCCESS',
  CURRENT_USER_ERROR = '@app/CURRENT_USER_ERROR',
  CURRENT_USER_LOADING = '@app/CURRENT_USER_LOADING',

  TOGGLE_MOBILE_MENU = '@app/TOGGLE_MOBILE_MENU',

  CHANGE_ORGANIZATION_SUCCESS = '@app/CHANGE_ORGANIZATION_SUCCESS',
  CHANGE_ORGANIZATION_ERROR = '@app/CHANGE_ORGANIZATION_ERROR',
  CHANGE_ORGANIZATION_LOADING = '@app/CHANGE_ORGANIZATION_LOADING',

  GET_TIMEZONES_SUCCESS = '@app/GET_TIMEZONES_SUCCESS',
  GET_TIMEZONES_ERROR = '@app/GET_TIMEZONES_ERROR',
  GET_TIMEZONES_LOADING = '@app/CHANGE_ORGANIZATION_LOADING',

  GET_EQUIPMENT_TYPES_SUCCESS = '@app/GET_EQUIPMENT_TYPES_SUCCESS',
  GET_EQUIPMENT_TYPES_ERROR = '@app/GET_EQUIPMENT_TYPES_ERROR',
  GET_EQUIPMENT_TYPES_LOADING = '@app/GET_EQUIPMENT_TYPES_LOADING',

  GET_EQUIPMENT_TYPES_BY_SITE_SUCCESS = '@app/GET_EQUIPMENT_TYPES_BY_SITE_SUCCESS',
  GET_EQUIPMENT_TYPES_BY_SITE_ERROR = '@app/GET_EQUIPMENT_TYPES_BY_SITE_ERROR',
  GET_EQUIPMENT_TYPES_BY_SITE_LOADING = '@app/GET_EQUIPMENT_TYPES_BY_SITE_LOADING',

  GET_EQUIPMENT_TYPES_BY_ORG_SUCCESS = '@app/GET_EQUIPMENT_TYPES_BY_ORG_SUCCESS',
  GET_EQUIPMENT_TYPES_BY_ORG_ERROR = '@app/GET_EQUIPMENT_TYPES_BY_ORG_ERROR',
  GET_EQUIPMENT_TYPES_BY_ORG_LOADING = '@app/GET_EQUIPMENT_TYPES_BY_ORG_LOADING',

  GET_EQUIPMENT_GROUPS_SUCCESS = '@app/GET_EQUIPMENT_GROUPS_SUCCESS',
  GET_EQUIPMENT_GROUPS_ERROR = '@app/GET_EQUIPMENT_GROUPS_ERROR',
  GET_EQUIPMENT_GROUPS_LOADING = '@app/GET_EQUIPMENT_GROUPS_LOADING',

  GET_FIRMWARES_SUCCESS = '@app/GET_FIRMWARES_SUCCESS',
  GET_FIRMWARES_ERROR = '@app/GET_FIRMWARES_ERROR',
  GET_FIRMWARES_LOADING = '@app/GET_FIRMWARES_LOADING',

  GET_AC_POWER_SYSTEMS_SUCCESS = '@app/GET_AC_POWER_SYSTEMS_SUCCESS',
  GET_AC_POWER_SYSTEMS_ERROR = '@app/GET_AC_POWER_SYSTEMS_ERROR',
  GET_AC_POWER_SYSTEMS_LOADING = '@app/GET_AC_POWER_SYSTEMS_LOADING',

  GET_BREAKER_ARRANGEMENTS_SUCCESS = '@app/GET_BREAKER_ARRANGEMENTS_SUCCESS',
  GET_BREAKER_ARRANGEMENTS_ERROR = '@app/GET_BREAKER_ARRANGEMENTS_ERROR',
  GET_BREAKER_ARRANGEMENTS_LOADING = '@app/GET_BREAKER_ARRANGEMENTS_LOADING',

  GET_BREAKER_TYPES_SUCCESS = '@app/GET_BREAKER_TYPES_SUCCESS',
  GET_BREAKER_TYPES_ERROR = '@app/GET_BREAKER_TYPES_ERROR',
  GET_BREAKER_TYPES_LOADING = '@app/GET_BREAKER_TYPES_LOADING',

  GET_BREAKER_RATINGS_SUCCESS = '@app/GET_BREAKER_RATINGS_SUCCESS',
  GET_BREAKER_RATINGS_ERROR = '@app/GET_BREAKER_RATINGS_ERROR',
  GET_BREAKER_RATINGS_LOADING = '@app/GET_BREAKER_RATINGS_LOADING',

  GET_DISTRIBUTION_PANEL_TYPES_SUCCESS = '@app/GET_DISTRIBUTION_PANEL_TYPES_SUCCESS',
  GET_DISTRIBUTION_PANEL_TYPES_ERROR = '@app/GET_DISTRIBUTION_PANEL_TYPES_ERROR',
  GET_DISTRIBUTION_PANEL_TYPES_LOADING = '@app/GET_DISTRIBUTION_PANEL_TYPES_LOADING',

  GET_ENERGY_CALCULATION_MODES_SUCCESS = '@app/GET_ENERGY_CALCULATION_MODES_SUCCESS',
  GET_ENERGY_CALCULATION_MODES_ERROR = '@app/GET_ENERGY_CALCULATION_MODES_ERROR',
  GET_ENERGY_CALCULATION_MODES_LOADING = '@app/GET_ENERGY_CALCULATION_MODES_LOADING',

  GET_ELECTRICAL_PHASES_SUCCESS = '@app/GET_ELECTRICAL_PHASES_SUCCESS',
  GET_ELECTRICAL_PHASES_ERROR = '@app/GET_ELECTRICAL_PHASES_ERROR',
  GET_ELECTRICAL_PHASES_LOADING = '@app/GET_ELECTRICAL_PHASES_LOADING',

  GET_ENERGY_SOURCES_SUCCESS = '@app/GET_ENERGY_SOURCES_SUCCESS',
  GET_ENERGY_SOURCES_ERROR = '@app/GET_ENERGY_SOURCES_ERROR',
  GET_ENERGY_SOURCES_LOADING = '@app/GET_ENERGY_SOURCES_LOADING',

  GET_ENERGY_DEVICE_MODELS_SUCCESS = '@app/GET_ENERGY_DEVICE_MODELS_SUCCESS',
  GET_ENERGY_DEVICE_MODELS_ERROR = '@app/GET_ENERGY_DEVICE_MODELS_ERROR',
  GET_ENERGY_DEVICE_MODELS_LOADING = '@app/GET_ENERGY_DEVICE_MODELS_LOADING',

  GET_ENERGY_PRO_CTS_SUCCESS = '@app/GET_ENERGY_PRO_CTS_SUCCESS',
  GET_ENERGY_PRO_CTS_ERROR = '@app/GET_ENERGY_PRO_CTS_ERROR',
  GET_ENERGY_PRO_CTS_LOADING = '@app/GET_ENERGY_PRO_CTS_LOADING',

  GET_ENERGY_SPIDER_CTS_SUCCESS = '@app/GET_ENERGY_SPIDER_CTS_SUCCESS',
  GET_ENERGY_SPIDER_CTS_ERROR = '@app/GET_ENERGY_SPIDER_CTS_ERROR',
  GET_ENERGY_SPIDER_CTS_LOADING = '@app/GET_ENERGY_SPIDER_CTS_LOADING',

  GET_ENERGY_SPIDER_FLEX_CTS_SUCCESS = '@app/GET_ENERGY_SPIDER_FLEX_CTS_SUCCESS',
  GET_ENERGY_SPIDER_FLEX_CTS_ERROR = '@app/GET_ENERGY_SPIDER_FLEX_CTS_ERROR',
  GET_ENERGY_SPIDER_FLEX_CTS_LOADING = '@app/GET_ENERGY_SPIDER_FLEX_CTS_LOADING',

  SET_LAST_SERVER_TIME = '@app/SET_LAST_SERVER_TIME',

  DISMISS_NEW_PASSWORD_POPUP = '@app/DISMISS_NEW_PASSWORD_POPUP',
}

export const initApp = () => ({
  type: Actions.INIT,
});

export const initAppWithToken = ({ accessToken }) => ({
  type: Actions.INIT_WITH_TOKEN,
  data: { accessToken },
});

export const logout = () => ({
  type: Actions.LOGOUT,
});

export const login = (credentials?: { email: string; password: string }) => (
  dispatch: any,
  getState: () => ApplicationState
) => {
  const { email, password } = credentials || getState().app.loginForm;

  dispatch({
    type: 'API_POST',
    path: '/api/v1/auth/login',
    payload: { email, password },
    success: Actions.LOGIN_SUCCESS,
    error: Actions.LOGIN_ERROR,
    loading: Actions.LOGIN_LOADING,
  });
};

export const forgotPassword = (email: string) => ({
  type: 'API_POST',
  payload: { email, website: 'CONNECT' },
  path: '/api/v1/users/password-forget',
  success: { type: Actions.FORGOT_PASSWORD_SUCCESS, email },
  error: Actions.FORGOT_PASSWORD_ERROR,
  loading: Actions.FORGOT_PASSWORD_LOADING,
});

export const resetPassword = (
  email: string,
  token: string,
  password: string,
  passwordVerify: string
) => ({
  type: 'API_POST',
  payload: { email, token, password, passwordVerify },
  path: '/api/v1/users/email-password-confirm',
  success: Actions.RESET_PASSWORD_SUCCESS,
  error: Actions.RESET_PASSWORD_ERROR,
  loading: Actions.RESET_PASSWORD_LOADING,
});

export const updateLoginField = (field: string, value: string) => ({
  type: Actions.UPDATE_LOGIN_FIELD,
  field: field,
  value: value,
});

export const updateForgotPasswordField = (field: string, value: string) => ({
  type: Actions.UPDATE_FORGOT_PASSWORD_FIELD,
  field: field,
  value: value,
});

export const updateResetPasswordField = (field: string, value: string) => ({
  type: Actions.UPDATE_RESET_PASSWORD_FIELD,
  field: field,
  value: value,
});

export const getCurrentUser = () => ({
  type: 'API_GET',
  path: '/api/v1/auth/user',
  success: Actions.CURRENT_USER_SUCCESS,
  error: Actions.CURRENT_USER_ERROR,
  loading: Actions.CURRENT_USER_LOADING,
});

export const switchOrganization = (id: number) => ({
  type: 'API_POST',
  path: `/api/v1/auth/switch/organization/${id}`,
  success: Actions.CHANGE_ORGANIZATION_SUCCESS,
  error: Actions.CHANGE_ORGANIZATION_ERROR,
  loading: Actions.CHANGE_ORGANIZATION_LOADING,
});

export const getTimeZones = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/timezones',
  success: Actions.GET_TIMEZONES_SUCCESS,
  error: Actions.GET_TIMEZONES_ERROR,
  loading: Actions.GET_TIMEZONES_LOADING,
});

export const getEquipmentGroups = () => ({
  type: 'API_GET',
  path: '/api/v1/equipment-groups',
  success: Actions.GET_EQUIPMENT_GROUPS_SUCCESS,
  error: Actions.GET_EQUIPMENT_GROUPS_ERROR,
  loading: Actions.GET_EQUIPMENT_GROUPS_LOADING,
});

export const getEquipmentTypes = () => ({
  type: 'API_GET',
  path: '/api/v1/equipment-types',
  success: Actions.GET_EQUIPMENT_TYPES_SUCCESS,
  error: Actions.GET_EQUIPMENT_TYPES_ERROR,
  loading: Actions.GET_EQUIPMENT_TYPES_LOADING,
});

export const getEquipmentTypesBySiteId = (id: number) => ({
  type: 'API_GET',
  path: `/api/v1/equipment-types/site/${id}`,
  success: Actions.GET_EQUIPMENT_TYPES_BY_SITE_SUCCESS,
  error: Actions.GET_EQUIPMENT_TYPES_BY_SITE_ERROR,
  loading: Actions.GET_EQUIPMENT_TYPES_BY_SITE_LOADING,
});

export const getEquipmentTypesByOrgId = (id: number) => ({
  type: 'API_GET',
  path: `/api/v1/equipment-types/organization/${id}`,
  success: Actions.GET_EQUIPMENT_TYPES_BY_ORG_SUCCESS,
  error: Actions.GET_EQUIPMENT_TYPES_BY_ORG_ERROR,
  loading: Actions.GET_EQUIPMENT_TYPES_BY_ORG_LOADING,
});

export type GetEquipmentParams = {
  gatewayId?: number | string;
  gatewayModels?: FirmwareGatewayModel[];
  ids?: number[];
  limit?: number;
  siblings?: boolean;
  tags?: string[];
  types?: ReleaseType;
};

const createQueryString = (params: GetEquipmentParams = {}) => {
  return Object.keys(params)
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getFirmwares = (queryPararms: GetEquipmentParams = {}) => ({
  type: 'API_GET',
  path: `/api/v1/firmware?${createQueryString(queryPararms)}`,
  success: Actions.GET_FIRMWARES_SUCCESS,
  error: Actions.GET_FIRMWARES_ERROR,
  loading: Actions.GET_FIRMWARES_LOADING,
});

export const getAcPowerSystems = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/ac-power-systems',
  success: Actions.GET_AC_POWER_SYSTEMS_SUCCESS,
  error: Actions.GET_AC_POWER_SYSTEMS_ERROR,
  loading: Actions.GET_AC_POWER_SYSTEMS_LOADING,
});

export const getBreakerArrangements = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/breaker-arrangements',
  success: Actions.GET_BREAKER_ARRANGEMENTS_SUCCESS,
  error: Actions.GET_BREAKER_ARRANGEMENTS_ERROR,
  loading: Actions.GET_BREAKER_ARRANGEMENTS_LOADING,
});

export const getBreakerRatings = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/breaker-ratings',
  success: Actions.GET_BREAKER_RATINGS_SUCCESS,
  error: Actions.GET_BREAKER_RATINGS_ERROR,
  loading: Actions.GET_BREAKER_RATINGS_LOADING,
});

export const getBreakerTypes = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/breaker-types',
  success: Actions.GET_BREAKER_TYPES_SUCCESS,
  error: Actions.GET_BREAKER_TYPES_ERROR,
  loading: Actions.GET_BREAKER_TYPES_LOADING,
});

export const getDistributionPanelTypes = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/distribution-panel-types',
  success: Actions.GET_DISTRIBUTION_PANEL_TYPES_SUCCESS,
  error: Actions.GET_DISTRIBUTION_PANEL_TYPES_ERROR,
  loading: Actions.GET_DISTRIBUTION_PANEL_TYPES_LOADING,
});

export const getEnergyCalculationModes = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/site-energy-calculation-modes',
  success: Actions.GET_ENERGY_CALCULATION_MODES_SUCCESS,
  error: Actions.GET_ENERGY_CALCULATION_MODES_ERROR,
  loading: Actions.GET_ENERGY_CALCULATION_MODES_LOADING,
});

export const getElectricalPhases = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/electrical-phases',
  success: Actions.GET_ELECTRICAL_PHASES_SUCCESS,
  error: Actions.GET_ELECTRICAL_PHASES_ERROR,
  loading: Actions.GET_ELECTRICAL_PHASES_LOADING,
});

export const getEnergySources = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/energy-sources',
  success: Actions.GET_ENERGY_SOURCES_SUCCESS,
  error: Actions.GET_ENERGY_SOURCES_ERROR,
  loading: Actions.GET_ENERGY_SOURCES_LOADING,
});

export const getEnergyDeviceModels = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/energy-device-models',
  success: Actions.GET_ENERGY_DEVICE_MODELS_SUCCESS,
  error: Actions.GET_ENERGY_DEVICE_MODELS_ERROR,
  loading: Actions.GET_ENERGY_DEVICE_MODELS_LOADING,
});

export const getEnergyProCts = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/energy-pro-ct',
  success: Actions.GET_ENERGY_PRO_CTS_SUCCESS,
  error: Actions.GET_ENERGY_PRO_CTS_ERROR,
  loading: Actions.GET_ENERGY_PRO_CTS_LOADING,
});

export const getEnergySpiderCts = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/energy-spider-ct',
  success: Actions.GET_ENERGY_SPIDER_CTS_SUCCESS,
  error: Actions.GET_ENERGY_SPIDER_CTS_ERROR,
  loading: Actions.GET_ENERGY_SPIDER_CTS_LOADING,
});

export const getEnergySpiderFlexCts = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/energy-spider-flex-ct',
  success: Actions.GET_ENERGY_SPIDER_FLEX_CTS_SUCCESS,
  error: Actions.GET_ENERGY_SPIDER_FLEX_CTS_ERROR,
  loading: Actions.GET_ENERGY_SPIDER_FLEX_CTS_LOADING,
});

export const toggleMobileMenu = () => ({
  type: Actions.TOGGLE_MOBILE_MENU,
});

export const dismissNewPasswordPopup = () => ({
  type: Actions.DISMISS_NEW_PASSWORD_POPUP,
});
