import {
  EnergyDeviceFromApi,
  EnergyPro,
  EnergySensor,
} from '@energybox/react-ui-library/dist/types';
import {
  SubscribedEnergyPro,
  EnergyProSensorReading,
} from '../reducers/subscribedEnergyPros';
import {
  ProcessedEnergySensor,
  ProcessedEnergySensorsById,
} from '../types/energyDevice';

export type ProcessedSubscribedEnergySensor = {
  indexString: string;
  powerActive: number;
  powerReactive: number;
  current: number;
  powerFactor: number;
  voltage: number;
  sensorId: number;
  isMainBreaker: boolean;
  breakerId?: number;
  breakerName?: string;
  energyDeviceId?: number;
  port?: number;
  isSiteTotal?: boolean;
  panelTitle?: string;
  equipmentTitle?: string;
  isMainPanel: boolean;
  isTotal?: boolean;
};

export const processSubscribedEnergyProSensors = (
  subscribedEnergyPro: SubscribedEnergyPro | undefined,
  energySensorsById: ProcessedEnergySensorsById
): ProcessedSubscribedEnergySensor[] => {
  if (!subscribedEnergyPro) return [];

  return subscribedEnergyPro.sensors.map((s: EnergyProSensorReading) => {
    const energySensor = energySensorsById[s.id];
    const { breaker, energyDeviceId, port, panelTitle, isMainPanel } =
      energySensor || {};
    const { id: breakerId, title: breakerName, _entity, siteTotal } =
      breaker || {};
    const isMainBreaker = _entity === 'MainBreaker';

    return {
      indexString: s.indexString,
      powerActive: s.powerActive,
      powerReactive: s.powerReactive,
      current: s.current,
      powerFactor: s.powerFactor,
      voltage: s.voltage,
      sensorId: s.id,
      equipmentTitle: breaker?.equipment?.title,
      breakerId,
      breakerName,
      isSiteTotal: siteTotal,
      isMainBreaker,
      energyDeviceId,
      port,
      panelTitle,
      isMainPanel,
    } as ProcessedSubscribedEnergySensor;
  });
};

export const getEnergyDeviceSensorsOfEnergyPro = (
  energyPro: EnergyPro | undefined,
  options: {
    mapEnergyDeviceSensorsReduxAction?: (
      sensors: EnergySensor[],
      energyDeviceId: number
    ) => void;
  } = {}
): ProcessedEnergySensor[] | undefined => {
  const { mapEnergyDeviceSensorsReduxAction } = options;
  const energyDeviceSensors: EnergySensor[] = [];
  if (!energyPro) return undefined;

  const recursivelyGetSensors = (energyDevice: EnergyDeviceFromApi | null) => {
    if (!energyDevice) return;

    if (energyDevice.sensors) {
      if (mapEnergyDeviceSensorsReduxAction) {
        mapEnergyDeviceSensorsReduxAction(
          energyDevice.sensors,
          energyDevice.id
        );
      }

      energyDeviceSensors.push(...energyDevice.sensors);
    }

    if (energyDevice.busDevice) {
      recursivelyGetSensors(energyDevice.busDevice);
    }
  };

  if (mapEnergyDeviceSensorsReduxAction) {
    mapEnergyDeviceSensorsReduxAction(energyPro.sensors, energyPro.id);
  }
  recursivelyGetSensors(energyPro.bus1Device);
  recursivelyGetSensors(energyPro.bus2Device);

  energyDeviceSensors.push(...energyPro.sensors);

  return energyDeviceSensors.map(s => {
    return {
      ...s,
      panelTitle: energyPro.distributionPanel?.title,
      isMainPanel: energyPro.distributionPanel?.mdp || false,
    };
  });
};
