import {
  Loader,
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { EnergySpiderCt } from '@energybox/react-ui-library/dist/types';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getEnergySpiderCts } from '../../actions/app';
import { ApplicationState } from '../../reducers';

interface OwnProps {
  onSelect: (energySpiderCtId: string) => void;
  value?: string;
  error: boolean;
  disabled?: boolean;
  noBottomLine?: boolean;
}

interface Props extends OwnProps {
  getEnergySpiderCts: typeof getEnergySpiderCts;
  energySpiderCts?: EnergySpiderCt[];
}

interface State {
  search: string;
}

class SelectEnergySpiderCt extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      search: '',
    };
  }

  searchChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      search: e.currentTarget.value,
    });
  }

  resetSearch() {
    this.setState({ search: '' });
  }

  componentDidMount() {
    const { energySpiderCts, getEnergySpiderCts } = this.props;
    if (!energySpiderCts) getEnergySpiderCts();
  }

  render() {
    const {
      energySpiderCts,
      value,
      onSelect,
      error,
      disabled,
      noBottomLine = false,
    } = this.props;

    if (!energySpiderCts) {
      return <Loader size={12} />;
    } else {
      const { search } = this.state;

      const listedEnergySpiderCts = (energySpiderCts || []).filter(
        (eq: EnergySpiderCt) => {
          if (search.length <= 2) return true;
          return hasSubstr(eq.title, search);
        }
      );

      const selectedEnergySpiderCt = energySpiderCts.find(
        eq => eq.id === value
      );

      return (
        <Select
          disabled={disabled}
          variant={'select'}
          onClick={this.resetSearch.bind(this)}
          title={selectedEnergySpiderCt?.title}
          value={selectedEnergySpiderCt?.id}
          error={error}
          customErrorText={'Invalid CT type'}
          noBottomLine={noBottomLine}
        >
          <SelectSearch
            onChange={this.searchChange.bind(this)}
            value={search}
          />
          {listedEnergySpiderCts.map((eq: EnergySpiderCt) => (
            <SelectItem
              key={eq.id}
              isSelected={eq.id === selectedEnergySpiderCt?.id}
              onSelect={() => onSelect(eq.id)}
            >
              {eq.title}
            </SelectItem>
          ))}
        </Select>
      );
    }
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  energySpiderCts: app.energySpiderCts,
});

const mapDispatchToProps = {
  getEnergySpiderCts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectEnergySpiderCt);
