import {
  Button,
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { GenericErrors } from '@energybox/react-ui-library/dist/types';
import { hasKeys } from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import {
  create,
  displayFormErrors,
  hideNewDistributionPanelModal,
  updateField,
} from '../../actions/distribution_panel';
import EditDistributionPanelForm from '../../components/EditDistributionPanelForm';
import { ApplicationState } from '../../reducers';
import { EditableFields } from '../../reducers/distribution_panels';
import { CreateNewText } from '../../types/global';
import { ApiError } from '../../utils/apiErrorFeedback';

interface Props {
  onChange: (field: string, value: string | number) => void;
  onCreate: () => void;
  onClose: () => void;
  fields: EditableFields;
  isLoading: boolean;
  formErrors: GenericErrors;
  isVisible: boolean;
  lockSiteId?: number;
  formErrorsVisible: boolean;
  displayFormErrors: (id: string) => void;
  apiError: ApiError;
}

function NewDistributionPanelModal(props: Props) {
  if (!props.isVisible) return null;

  const {
    fields,
    onChange,
    onClose,
    onCreate,
    isLoading,
    formErrors,
    lockSiteId,
    formErrorsVisible,
    displayFormErrors,
    apiError,
  } = props;

  const onDistributionPanelCreate = () => {
    if (hasKeys(formErrors)) {
      displayFormErrors('new');
    } else {
      onCreate();
    }
  };

  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>

      <Button disabled={isLoading} onClick={onDistributionPanelCreate}>
        Create
      </Button>
    </>
  );

  return (
    <Modal actions={actions}>
      <ModalTitle>{CreateNewText.DISTRIBUTION_PANEL}</ModalTitle>
      <ModalContent>
        <EditDistributionPanelForm
          fields={fields}
          onChange={onChange}
          lockSiteId={lockSiteId}
          formErrorsVisible={formErrorsVisible}
          formErrors={formErrors}
          apiError={apiError}
        />
      </ModalContent>
    </Modal>
  );
}

const mapStateToProps = ({ distributionPanels }: ApplicationState) => {
  return {
    isVisible: distributionPanels.showNewDistributionPanelModal,
    ...distributionPanels.editById['new'],
  };
};

const mapDispatchToProps = {
  onClose: hideNewDistributionPanelModal,
  onChange: (field: string, value: string | number) =>
    updateField('new', field, value),
  onCreate: create,
  displayFormErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDistributionPanelModal);
