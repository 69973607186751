import {
  Button,
  Card,
  CardContent,
  CardTitle,
  Checkbox,
  MenuDropdown,
  MenuDropdownItem,
  Modal,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  Actuator,
  BreakerType,
  CircuitBreakerFromApiResponse,
  CircuitBreakerInPanel,
  DistributionPanel,
  EnergyDeviceFromApi,
  EnergyDevicePorts,
  EnergyPro,
  EnergySensor,
  FirmwareGatewayModel,
  GatewayStates,
  MainBreaker,
  ObjectById,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  KW_UNIT,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { EnergyProSensorReading } from '../../../reducers/subscribedEnergyPros';
import { getEnergyDeviceSensorsOfEnergyPro } from '../../../utils/energyPro';
import SelectCtPolarity from '../../Selects/SelectCtPolarity';
import SelectEquipment from '../../Selects/SelectEquipment';
import SelectPhase from '../../Selects/SelectPhase';
import SelectActiveEnergyPro from '../SelectActiveEnergyPro';

import equals from 'ramda/src/equals';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import pipe from 'ramda/src/pipe';
import rPick from 'ramda/src/pick';

import styles from './DPSetUpTable.module.css';
import { SelectEnergyDeviceCt } from '../../../components/EditEnergyDeviceSensorForm/EditEnergyDeviceSensorForm';
import UpdateModal from '../UpdateModal';

import {
  Actions as EnergyProActions,
  destroy,
  hideDeleteEnergyProModal,
  removeAllEnergyDevices,
  removeEnergyDeviceFromBus,
  showDeleteEnergyProModal,
} from '../../../actions/energy_pros';
import {
  patch as patchCircuitBreaker,
  destroy as deleteBreaker,
  showNewCircuitBreakerModal,
} from '../../../actions/circuit_breakers';
import {
  editEnergyDeviceSensorPort,
  showNewEnergyDeviceSensorModal,
  updateEnergyDeviceSensorField,
} from '../../../actions/energy_devices';
import {
  Trash,
  EnergyPro as EnergyProIcon,
} from '@energybox/react-ui-library/dist/icons';

import {
  useActuatorsBySiteId,
  useActuatorsLiveData,
} from '../../../hooks/useControlBoard';
import { renderCircuitStatusText } from '../../Gateways/GatewayDetailPages/ShowControlBoardPage/ShowControlBoardPage';
import NewEnergyDeviceSensorModal from '../../EnergyDevices/NewEnergyDeviceSensorModal';
import DeviceOnlineState, {
  DisplayType,
} from '../../DeviceStatus/DeviceOnlineState';
import { EntityInfoToSub } from '@energybox/react-ui-library/dist/types/StreamApi';
import NewEquipmentModal from '../../Equipment/NewEquipmentModal';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import { patch as patchMainBreaker } from '../../../actions/main_breakers';
import UpdateFirmwareModal from '../../../components/UpdateFirmwareModal';
import {
  getMapFromArrayOneToMany,
  getMapFromArrayOneToOne,
} from '@energybox/react-ui-library/dist/utils/util';

enum UpdateEnergyDeviceAction {
  ENERGY_DEVICES_FROM_API = 'ENERGY_DEVICES_FROM_API',
  BREAKERS_FROM_API = 'BREAKERS_FROM_API',
  CT = 'ct',
  PORT = 'port',
  REVERSE_POLARITY = 'reversePolarity',
  PHASE = 'phase',
  BREAKER_ID = 'breakerId',
  BREAKER_POLE = 'breakerPole',
  BREAKER_EQUIPMENT_ID = 'equipmentId',
  SITE_TOTAL = 'siteTotal',
  DELETE_ENERGY_DEVICE = 'DELETE_ENERGY_DEVICE',
  DELETE_BREAKER = 'DELETE_BREAKER,',
}

enum UpdateEntity {
  FROM_API,
  BREAKER,
  ENERGY_DEVICE,
  MAIN_BREAKER,
  DELETE_BREAKER,
}

const acceptedSensorFieldKeys = [
  'breakerId',
  'breakerPole',
  'ct',
  // NOTE: for some reason
  // energyDevicePort and port refers to the same entity
  // but have different names
  'reversePolarity',
  'phase',
];

const acceptedPatchBreakerFields = [
  'id',
  'breakerTitle',
  'description',
  'equipmentId',
  'rating',
  'breakerColumn',
  'breakerSlot',
  'siteTotal',
  'subpanel',
];

type DPTableState = {
  [deviceId: number]: {
    [port: number]: DevicePortDisplayData;
  };
  breakersById: ObjectById<CircuitBreakerFromApiResponse>;
};

type ActionPayLoad = {
  type: UpdateEnergyDeviceAction;
  id: number;
  deviceTitle: string;
  port?: number;
  value?;
  breakerId?: number;
  payload?: DevicePortDisplayData[];
  entity: UpdateEntity;
};

const getUpdateConfirmationText = (
  payload: ActionPayLoad,
  activeEnergyPro: EnergyPro
) => {
  const { type, deviceTitle } = payload;
  let entityTitle = <div></div>;
  if (deviceTitle) {
    entityTitle = deviceTitle ? (
      <span className={styles.bold}>{deviceTitle}</span>
    ) : (
      <>this bus device</>
    );
  } else {
    entityTitle = activeEnergyPro.title ? (
      <span className={styles.bold}>{activeEnergyPro.title}</span>
    ) : (
      <>this Energy Pro</>
    );
  }
  if (type === UpdateEnergyDeviceAction.DELETE_ENERGY_DEVICE) {
    return <span>Are you sure you want to delete {entityTitle}?</span>;
  }

  return (
    <span>
      Are you sure you want to update the configuration of {entityTitle}?
    </span>
  );
};

type Props = {
  siteId: number;
  breakerId?: number;
  distributionPanel?: DistributionPanel;
  energyPros: EnergyPro[];
  setActiveEnergyProsId: (id: number) => void;
  activeEnergyProId: number;
  activeEnergyPro: EnergyPro | undefined;
};

const reducer = (state: DPTableState, action: ActionPayLoad): DPTableState => {
  switch (action.type) {
    case UpdateEnergyDeviceAction.DELETE_ENERGY_DEVICE:
      return dissocPath([action.id], state);

    case UpdateEnergyDeviceAction.ENERGY_DEVICES_FROM_API:
      if (action.payload === undefined) return state;

      let updatedState = { ...state };
      action.payload.forEach(sensorData => {
        const { energyDeviceId, port } = sensorData;
        updatedState = assocPath(
          [energyDeviceId, port],
          sensorData,
          updatedState
        );
      });

      return updatedState;

    case UpdateEnergyDeviceAction.BREAKERS_FROM_API:
      return { ...state, breakersById: action.value || {} };

    case UpdateEnergyDeviceAction.SITE_TOTAL:
      if (action.breakerId === undefined) return state;
      return assocPath(
        ['breakersById', action.breakerId, 'siteTotal'],
        action.value,
        state
      );
    case UpdateEnergyDeviceAction.BREAKER_EQUIPMENT_ID:
    case UpdateEnergyDeviceAction.PORT:
    case UpdateEnergyDeviceAction.CT:
    case UpdateEnergyDeviceAction.REVERSE_POLARITY:
    case UpdateEnergyDeviceAction.PHASE:
    case UpdateEnergyDeviceAction.BREAKER_ID:
    case UpdateEnergyDeviceAction.BREAKER_POLE:
      if (action.port === undefined) return state;
      return assocPath(
        [action.id, action.port, action.type],
        action.value,
        state
      );

    case UpdateEnergyDeviceAction.DELETE_BREAKER:
      if (action.breakerId === undefined) return state;
      return pipe(
        dissocPath(['breakersById', action.breakerId]),
        dissocPath([action.id, action.port])
      )(state);

    default:
      throw new Error();
  }
};

const initialState: DPTableState = {
  breakersById: {},
};

const DPSetUpTable = ({
  distributionPanel,
  siteId,
  energyPros,
  activeEnergyPro,
  setActiveEnergyProsId,
}: Props) => {
  /* Local states */
  const [showDeleteAllDevicesModal, setShowDeleteAllDevicesModal] = useState(
    false
  );
  const [showUpdateFirmwareModal, setShowUpdateFirmwareModal] = useState(false);
  const [unconfirmedUpdateAction, setUnconfirmedUpdateAction] = useState<
    ActionPayLoad | undefined
  >(undefined);

  /* Redux */
  const reduxDispatch = useDispatch();
  const showingDeleteEnergyProModal = useSelector(
    ({ energyPros }: ApplicationState) => {
      return activeEnergyPro
        ? energyPros.editById[activeEnergyPro.id].showDeleteEnergyProModal
        : false;
    }
  );

  ///*** useMemo values ***///
  const energySensors: EnergySensor[] = useSensorsFromEnergyPro(
    activeEnergyPro
  );

  const [localStore, localDispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!activeEnergyPro) return;
    const ports = energySensors.map(energySensor => {
      const breaker = energySensor?.breaker;
      const { _entity } = breaker || {};
      const isMainBreaker = _entity === 'MainBreaker';
      return {
        ...energySensor,
        energySensorId: energySensor.id,
        isMainBreaker,
      };
    });

    localDispatch({
      type: UpdateEnergyDeviceAction.ENERGY_DEVICES_FROM_API,
      deviceTitle: '',
      id: activeEnergyPro.id,
      payload: ports,
      entity: UpdateEntity.FROM_API,
    });
  }, [activeEnergyPro, energySensors]);

  useEffect(() => {
    if (!distributionPanel) return;
    const { breakers: breakersInPanelData } = distributionPanel;
    const rawBreakers = breakersInPanelData.map(({ breaker }) => {
      return breaker;
    });
    localDispatch({
      type: UpdateEnergyDeviceAction.BREAKERS_FROM_API,
      deviceTitle: '',
      // replace dummy id with proper undefined typing/logic
      id: 1,
      value: getMapFromArrayOneToOne(rawBreakers),
      entity: UpdateEntity.FROM_API,
    });
  }, [distributionPanel]);

  const onUpdateConfirm = useCallback(() => {
    if (!unconfirmedUpdateAction || !activeEnergyPro) return;
    const panelId = activeEnergyPro.distributionPanelId;
    const {
      id,
      port,
      type,
      value,
      entity,
      breakerId,
    } = unconfirmedUpdateAction;
    if (type === UpdateEnergyDeviceAction.DELETE_ENERGY_DEVICE) {
      if (!value) return;
      reduxDispatch(
        removeEnergyDeviceFromBus(activeEnergyPro.id, id, value.busNumber)
      );
      localDispatch(unconfirmedUpdateAction);
      setUnconfirmedUpdateAction(undefined);
      return;
    }
    if (!port) return;
    const portData = localStore[id][port];
    if (!portData) return;
    switch (entity) {
      case UpdateEntity.ENERGY_DEVICE: {
        const payload = {
          ...rPick(acceptedSensorFieldKeys, portData),
          energyDevicePort: port,
          // update the API before updating the local state
          // this is consistent with DP live reading table
          // no loading state while these values are being updated
          [type]: value,
        };
        reduxDispatch(editEnergyDeviceSensorPort(id, port, payload));
        localDispatch(unconfirmedUpdateAction);
        break;
      }
      case UpdateEntity.BREAKER: {
        const payload = {
          ...rPick(acceptedPatchBreakerFields, portData),
          id:
            type === UpdateEnergyDeviceAction.BREAKER_ID
              ? value
              : portData.breakerId,
          // update the API before updating the local state
          // this is consistent with DP live reading table
          // no loading state while these values are being updated
          [type]: value,
        };
        // rename object key breakerId into id.
        delete payload['breakerId'];
        reduxDispatch(patchCircuitBreaker(panelId, payload.id, payload));
        localDispatch(unconfirmedUpdateAction);
        break;
      }
      case UpdateEntity.DELETE_BREAKER: {
        if (breakerId === undefined) break;
        reduxDispatch(deleteBreaker(panelId, breakerId));
        localDispatch(unconfirmedUpdateAction);
        break;
      }
      case UpdateEntity.MAIN_BREAKER: {
        if (!breakerId || !distributionPanel) break;
        const { mainBreaker } = distributionPanel;
        const payload = {
          [type]: value,
        };
        reduxDispatch(patchMainBreaker(mainBreaker.id, panelId, payload));
        break;
      }
      default:
        break;
    }

    setUnconfirmedUpdateAction(undefined);
  }, [localStore, unconfirmedUpdateAction, activeEnergyPro, distributionPanel]);

  const energyProSubscriptionInfo:
    | EntityInfoToSub[]
    | undefined = useMemo(() => {
    if (!activeEnergyPro) return undefined;
    const { id, uuid, vendor } = activeEnergyPro;
    return [
      {
        id,
        uuid,
        vendor,
      },
    ];
  }, [activeEnergyPro]);

  const bus1RootDevice = activeEnergyPro?.bus1Device;
  const bus2RootDevice = activeEnergyPro?.bus2Device;

  const [bus1Nodes, bus1DeviceById] = useMemo(() => {
    const nodes: EnergyDeviceFromApi[] = [];

    buildSensorBusNodes(bus1RootDevice, nodes);
    const deviceById: ObjectById<EnergyDeviceFromApi> = mapArrayToObject(nodes);
    // remove the root node
    // root is being rendered separately
    return [nodes, deviceById];
  }, [bus1RootDevice]);

  const [bus2Nodes, bus2DeviceById] = useMemo(() => {
    const nodes: EnergyDeviceFromApi[] = [];

    buildSensorBusNodes(bus2RootDevice, nodes);
    const deviceById: ObjectById<EnergyDeviceFromApi> = mapArrayToObject(nodes);
    // remove the root node
    // root is being rendered separately
    return [nodes, deviceById];
  }, [bus2RootDevice]);

  // if bus1 has an odd number of devices
  // highlight the first row of bus2
  const highlightBus2FirstRow = bus1Nodes.length % 2 !== 0;

  if (!activeEnergyPro || !distributionPanel) return null;

  return (
    <>
      <UpdateFirmwareModal
        device={activeEnergyPro}
        onClose={() => setShowUpdateFirmwareModal(false)}
        isVisible={showUpdateFirmwareModal}
        firmwareGatewayModel={FirmwareGatewayModel.ENERGYPRO}
      />
      {showDeleteAllDevicesModal && (
        <ConfirmDeleteAllEnergyDevices
          energyPro={activeEnergyPro}
          onClose={() => setShowDeleteAllDevicesModal(false)}
        />
      )}

      {showingDeleteEnergyProModal && (
        <ConfirmDeleteEnergyPro
          energyPro={activeEnergyPro}
          onDelete={() => {
            const energyProsAfterDelete = energyPros.filter(
              item => item.id !== activeEnergyPro.id
            );
            if (energyProsAfterDelete.length) {
              setActiveEnergyProsId(energyProsAfterDelete[0].id);
            }
          }}
        />
      )}
      <Card>
        <CardContent>
          <CardTitle className={styles.cardHeader}>
            <div className={styles.selectEnergyPro}>
              <div className={styles.energyProLabel}>
                {energyProSubscriptionInfo && (
                  <div className={styles.energyProHeaderMargin}>
                    <DeviceOnlineState
                      displayType={DisplayType.STATUS_ONLY_WITHOUT_TEXT}
                      devices={energyProSubscriptionInfo}
                    />
                  </div>
                )}
                <EnergyProIcon
                  className={styles.energyProHeaderMargin}
                  size={20}
                />
                <div className={styles.energyProHeaderMargin}>Energy Pro</div>
              </div>
              <div className={styles.energyProHeaderMargin}>
                <SelectActiveEnergyPro
                  energyPros={energyPros}
                  activeEnergyPro={activeEnergyPro}
                  onChange={setActiveEnergyProsId}
                />
              </div>
            </div>
            <MenuDropdown>
              <MenuDropdownItem
                onSelect={() => {
                  setShowUpdateFirmwareModal(true);
                }}
              >
                Update Firmware
              </MenuDropdownItem>
              <MenuDropdownItem
                isRed
                onSelect={() => {
                  setShowDeleteAllDevicesModal(true);
                }}
              >
                Delete All Energy Devices
              </MenuDropdownItem>
              <MenuDropdownItem
                isRed
                onSelect={() => {
                  reduxDispatch(showDeleteEnergyProModal(activeEnergyPro.id));
                }}
              >
                Delete Energy Pro
              </MenuDropdownItem>
            </MenuDropdown>
          </CardTitle>
          {/* <Table
            data={tableData}
            columns={columns}
            groupHeaders={groupHeaders}
          /> */}
          <EnergyProConfig
            distributionPanel={distributionPanel}
            siteId={siteId}
            configByDeviceId={localStore}
            energyPro={activeEnergyPro}
            setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
          />
          <SensorBusTree
            busNumber={1}
            title="Sensor Bus 1"
            distributionPanel={distributionPanel}
            nodes={bus1Nodes}
            deviceById={bus1DeviceById}
            siteId={siteId}
            configByDeviceId={localStore}
            energyPro={activeEnergyPro}
            setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
            localDispatch={localDispatch}
          />
          <SensorBusTree
            busNumber={2}
            highlightFromFirstRow={highlightBus2FirstRow}
            title="Sensor Bus 2"
            distributionPanel={distributionPanel}
            nodes={bus2Nodes}
            deviceById={bus2DeviceById}
            siteId={siteId}
            configByDeviceId={localStore}
            energyPro={activeEnergyPro}
            setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
            localDispatch={localDispatch}
          />
        </CardContent>
      </Card>
      {unconfirmedUpdateAction && (
        <UpdateModal
          onCancelModal={() => setUnconfirmedUpdateAction(undefined)}
          onConfirmUpdate={onUpdateConfirm}
          modalText={getUpdateConfirmationText(
            unconfirmedUpdateAction,
            activeEnergyPro
          )}
          apiErrorAction={
            EnergyProActions.UPDATE_ENERGY_PRO_CONFIGURATION_ERROR
          }
        />
      )}
    </>
  );
};

const ConfirmDeleteAllEnergyDevices = ({
  energyPro,
  onClose,
}: {
  energyPro: EnergyPro;
  onClose: () => void;
}) => {
  const reduxDispatch = useDispatch();
  const actions = (
    <>
      <Button variant="text" onClick={onClose}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          reduxDispatch(removeAllEnergyDevices(energyPro.id));
          onClose();
        }}
      >
        Remove All
      </Button>
    </>
  );
  return (
    <Modal actions={actions}>
      <p style={{ textAlign: 'center', padding: '1rem' }}>
        Are you sure you want to remove all Energy Devices from{' '}
        <b>{energyPro.title}</b>?
      </p>
    </Modal>
  );
};

const ConfirmDeleteEnergyPro = ({
  energyPro,
  onDelete,
}: {
  energyPro: EnergyPro;
  onDelete: () => void;
}) => {
  const reduxDispatch = useDispatch();

  const editEnergyPro = useSelector(({ energyPros }: ApplicationState) => {
    return energyPros.editById[energyPro.id];
  });

  const actions = (
    <>
      <Button
        variant="text"
        onClick={() => reduxDispatch(hideDeleteEnergyProModal(energyPro.id))}
      >
        Cancel
      </Button>
      <Button
        onClick={() => {
          reduxDispatch(destroy(energyPro.id));
          reduxDispatch(hideDeleteEnergyProModal(energyPro.id));
          onDelete();
        }}
      >
        Delete
      </Button>
    </>
  );

  return (
    <Modal onClose={hideDeleteEnergyProModal} actions={actions}>
      <p style={{ textAlign: 'center' }}>
        Are you sure you want to delete{' '}
        {energyPro ? <b>{energyPro.title}</b> : 'this energy pro'}?
      </p>
      {editEnergyPro &&
        renderAPIerror(
          editEnergyPro.apiError,
          EnergyProActions.DELETE_ENERGY_PRO_ERROR
        )}
    </Modal>
  );
};

type EnergyProConfigProps = {
  distributionPanel: DistributionPanel;
  siteId: number;
  configByDeviceId: DPTableState;
  energyPro: EnergyPro;
  setUnconfirmedUpdateAction: (updateAction: ActionPayLoad | undefined) => void;
};

const EnergyProConfig = ({
  distributionPanel,
  siteId,
  configByDeviceId,
  energyPro,
  setUnconfirmedUpdateAction,
}: EnergyProConfigProps) => {
  const { breakers } = distributionPanel;
  const portsData = Object.values(configByDeviceId[energyPro.id] || {});
  const energySensorsReadingById = useEnergyProStreamDataBySensorId(
    energyPro.id
  );

  const actuators = useActuatorsBySiteId(siteId);

  const {
    controlBoardsLiveDataById,
    controlBoardsLiveOutputStates,
  } = useActuatorsLiveData(actuators);

  const { breakersById } = configByDeviceId;
  return (
    <>
      <div className={classNames(styles.busDeviceTable, styles.topSeparator)}>
        <div className={styles.tableHeader}>Device Name</div>
        <div className={styles.tableHeader}>Port</div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          CT Type
        </div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          CT Polarity
        </div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Phase
        </div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Breaker
        </div>
        <div className={styles.tableHeader}>Site Total</div>
        <div className={styles.tableHeader}>Active Power ({KW_UNIT}/Pf)</div>
        <div className={styles.tableHeader}>Current (A)</div>
        <div
          className={classNames(
            styles.tableHeader,
            styles.dropdownHeaderPadding
          )}
        >
          Equipment
        </div>
        <div className={styles.tableHeader}>
          Site Controller Relay Port/Circuit Status
        </div>
        <div className={styles.tableHeader} />
      </div>
      <div
        className={classNames(
          styles.busDeviceTable,
          styles.busDeviceHighlightRow
        )}
      >
        <div className={classNames(styles.busDeviceTitle)}>
          <div>{energyPro.title}</div>
        </div>
        {portsData.map((data, row, rows) => {
          const {
            port,
            energyDeviceId,
            energySensorId,
            ct,
            reversePolarity,
            phase,
            breakerId,
            isMainBreaker,
          } = data;

          const breaker: MainBreaker | undefined =
            distributionPanel.mainBreaker;

          return (
            <React.Fragment key={`CT_Port${port}`}>
              {row !== 0 && <div>&nbsp;</div>}
              <div>Port {port}</div>
              <div>
                <SelectEnergyDeviceCt
                  noBottomLine
                  deviceType={ResourceType.ENERGYPRO}
                  value={ct}
                  onSelect={updated =>
                    setUnconfirmedUpdateAction({
                      type: UpdateEnergyDeviceAction.CT,
                      deviceTitle: energyPro.title,
                      id: energyDeviceId,
                      port,
                      value: updated,
                      entity: UpdateEntity.ENERGY_DEVICE,
                    })
                  }
                />
              </div>
              <div>
                <SelectCtPolarity
                  noBottomLine
                  value={reversePolarity}
                  onSelect={updated =>
                    setUnconfirmedUpdateAction({
                      type: UpdateEnergyDeviceAction.REVERSE_POLARITY,
                      deviceTitle: energyPro.title,
                      id: energyDeviceId,
                      port,
                      value: updated,
                      entity: UpdateEntity.ENERGY_DEVICE,
                    })
                  }
                  error={false}
                />
              </div>
              <div>
                <SelectPhase
                  noBottomLine
                  value={phase}
                  onSelect={updated =>
                    setUnconfirmedUpdateAction({
                      type: UpdateEnergyDeviceAction.PHASE,
                      deviceTitle: energyPro.title,
                      id: energyDeviceId,
                      port,
                      value: updated,
                      entity: UpdateEntity.ENERGY_DEVICE,
                    })
                  }
                  error={false}
                />
              </div>
              <div>
                <BreakerDropdown
                  disabled
                  selectedBreaker={data.breaker}
                  selectedBreakerPole={data.breakerPole}
                  energyDeviceId={data.energyDeviceId}
                  port={data.port}
                  deviceTitle={energyPro.title}
                  breakers={breakers}
                  setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                />
              </div>
              <div>
                <Checkbox
                  checked={breaker?.siteTotal}
                  disabled={breaker === undefined}
                  onChange={() => {
                    if (breaker)
                      setUnconfirmedUpdateAction({
                        type: UpdateEnergyDeviceAction.SITE_TOTAL,
                        deviceTitle: energyPro.title,
                        id: energyDeviceId,
                        breakerId: +breaker.id,
                        port,
                        value: !breaker.siteTotal,
                        entity: UpdateEntity.MAIN_BREAKER,
                      });
                  }}
                />
              </div>
              <div>
                {`${getEnergy(
                  energySensorId,
                  energySensorsReadingById
                )} / ${getPowerFactor(
                  energySensorId,
                  energySensorsReadingById
                )}`}
              </div>
              <div>{getCurrent(energySensorId, energySensorsReadingById)}</div>
              <div>-</div>
              <div>-</div>
              <div>-</div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

type SensorBusTreeProps = {
  busNumber: number;
  title: string;
  distributionPanel: DistributionPanel;
  siteId: number;
  configByDeviceId: DPTableState;
  energyPro: EnergyPro;
  setUnconfirmedUpdateAction: (updateAction: ActionPayLoad | undefined) => void;
  nodes: EnergyDeviceFromApi[];
  deviceById: ObjectById<EnergyDeviceFromApi>;
  highlightFromFirstRow?: boolean;
  localDispatch: React.Dispatch<ActionPayLoad>;
};

const SensorBusTree = ({
  busNumber,
  title,
  distributionPanel,
  siteId,
  configByDeviceId,
  energyPro,
  setUnconfirmedUpdateAction,
  deviceById,
  nodes,
  highlightFromFirstRow,
  localDispatch,
}: SensorBusTreeProps) => {
  const reduxDispatch = useDispatch();

  const showingNewEnergyDeviceSensorModal = useSelector(
    ({ energyDevices }: ApplicationState) => {
      return energyDevices.showNewEnergyDeviceSensorModal;
    }
  );

  const [selectedDevice, setSelectedDevice] = useState<
    EnergyDeviceFromApi | undefined
  >(undefined);

  const [newEquipmentPendingAction, setNewEquipmentPendingAction] = useState<
    ActionPayLoad | undefined
  >(undefined);

  const circuitbreakerEditById = useSelector(
    ({ circuitBreakers }: ApplicationState) => {
      return circuitBreakers.editById;
    }
  );

  useEffect(() => {
    if (!newEquipmentPendingAction) return;
    const { id: energyDeviceId, port } = newEquipmentPendingAction;
    if (!port) return;
    const portData = configByDeviceId[energyDeviceId][port];
    const { breakerId } = portData;
    const equipmentIdFromRedux: number | undefined =
      circuitbreakerEditById[breakerId]?.fields?.equipmentId;
    if (equipmentIdFromRedux === undefined) return;
    if (
      equipmentIdFromRedux !==
      configByDeviceId.breakersById[breakerId]?.equipmentId
    ) {
      localDispatch({
        ...newEquipmentPendingAction,
        value: equipmentIdFromRedux,
      });
      const payload = {
        ...rPick(acceptedPatchBreakerFields, portData),
        id: breakerId,
        equipmentId: equipmentIdFromRedux,
      };
      reduxDispatch(
        patchCircuitBreaker(distributionPanel.id, breakerId, payload)
      );
      setNewEquipmentPendingAction(undefined);
    }
  }, [
    configByDeviceId,
    circuitbreakerEditById,
    newEquipmentPendingAction,
    distributionPanel,
    localDispatch,
  ]);

  const { breakers } = distributionPanel;
  const energySensorsReadingById = useEnergyProStreamDataBySensorId(
    energyPro.id
  );

  const actuators = useActuatorsBySiteId(siteId);

  const actuatorsByEquipmentId: ObjectById<Actuator[]> = useMemo(() => {
    return getMapFromArrayOneToMany(actuators || [], 'equipmentId');
  }, [actuators]);

  const subscribedControlBoardOutputStates = useSelector(
    ({ subscribedControlBoardOutputStates }: ApplicationState) =>
      subscribedControlBoardOutputStates
  );

  const {
    controlBoardsLiveDataById,
    controlBoardsLiveOutputStates,
  } = useActuatorsLiveData(actuators);

  if (nodes.length === 0) return null;

  const { breakersById } = configByDeviceId;
  return (
    <div className={styles.busDeviceRoot}>
      {showingNewEnergyDeviceSensorModal && selectedDevice && (
        <NewEnergyDeviceSensorModal
          panelId={distributionPanel.id}
          energyDeviceId={selectedDevice.id}
          sensors={selectedDevice.sensors}
          refetchEnergyPro
          energyProId={energyPro.id}
        />
      )}
      <div className={styles.deviceSeparator} />
      <div className={styles.busDeviceHeader}>
        <div>{title}</div>
      </div>
      {nodes.map((node, deviceIndex, devicesList) => {
        const device = deviceById[node.id];
        const portsData = mapArrayToObject(
          Object.values(configByDeviceId[node.id] || {}),
          'port'
        );
        const portRows = new Array(EnergyDevicePorts[device._entity]).fill(0);
        const isHighlighted = highlightFromFirstRow
          ? deviceIndex % 2 === 0
          : deviceIndex % 2 > 0;
        //   <MenuDropdown>
        //   <MenuDropdownItem onSelect={() => {
        //     reduxDispatch(showNewEnergyDeviceSensorModal(device.))
        //   }}>
        //     Set up new sensor
        //   </MenuDropdownItem>
        //   <MenuDropdownItem
        //     isRed
        //     onSelect={() => {
        //       setRemoveAllEnergyDeviceSensors(true)
        //     }}
        //   >
        //     Remove all sensors
        //   </MenuDropdownItem>
        // </MenuDropdown>
        return (
          <div
            className={classNames(
              styles.busDeviceTable,
              isHighlighted ? styles.busDeviceHighlightRow : undefined
            )}
            key={`busDevice${node.id}`}
          >
            <div className={classNames(styles.busDeviceTitle)}>
              <div>{device.title}</div>
              <div>
                {deviceIndex === devicesList.length - 1 && (
                  <Trash
                    onClick={() =>
                      setUnconfirmedUpdateAction({
                        entity: UpdateEntity.ENERGY_DEVICE,
                        deviceTitle: device.title,
                        type: UpdateEnergyDeviceAction.DELETE_ENERGY_DEVICE,
                        id: device.id,
                        value: { busNumber },
                      })
                    }
                    className={styles.deleteDeviceIcon}
                    size={16}
                    color="var(--pink-base)"
                  />
                )}
              </div>
            </div>
            {portRows.map((_, row) => {
              const data = portsData[row + 1];
              if (data === undefined) {
                return (
                  <React.Fragment key={`bus${node.id}DevicePort${row + 1}`}>
                    {row !== 0 && (
                      <div
                        className={styles.emptyPort}
                        onClick={() => {
                          reduxDispatch(
                            showNewEnergyDeviceSensorModal(device.id)
                          );
                          setSelectedDevice(device);
                        }}
                      >
                        &nbsp;
                      </div>
                    )}
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      Port {row + 1}
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      -
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      -
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      -
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        reduxDispatch(
                          updateEnergyDeviceSensorField(
                            'new',
                            'energyDevicePort',
                            row + 1,
                            device.id
                          )
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      <BreakerDropdown
                        setupMode
                        selectedBreaker={undefined}
                        selectedBreakerPole={undefined}
                        energyDeviceId={device.id}
                        port={row + 1}
                        deviceTitle={device.title}
                        breakers={breakers}
                        setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                      />
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      -
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      -
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      -
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      -
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      -
                    </div>
                    <div
                      className={styles.emptyPort}
                      onClick={() => {
                        reduxDispatch(
                          showNewEnergyDeviceSensorModal(device.id)
                        );
                        setSelectedDevice(device);
                      }}
                    >
                      -
                    </div>
                  </React.Fragment>
                );
              }
              const {
                port,
                energyDeviceId,
                energySensorId,
                ct,
                reversePolarity,
                phase,
                breakerId,
                isMainBreaker,
              } = data;

              const breaker: CircuitBreakerFromApiResponse | undefined =
                breakersById[breakerId];

              const actuators = breaker?.equipmentId
                ? actuatorsByEquipmentId[breaker.equipmentId]
                : undefined;

              if (!device) return null;
              return (
                <React.Fragment key={`bus${node.id}DevicePort${port}`}>
                  {row !== 0 && <div>&nbsp;</div>}
                  <div>Port {port}</div>
                  <div>
                    <SelectEnergyDeviceCt
                      noBottomLine
                      deviceType={device._entity}
                      value={ct}
                      onSelect={updated =>
                        setUnconfirmedUpdateAction({
                          type: UpdateEnergyDeviceAction.CT,
                          deviceTitle: device.title,
                          id: energyDeviceId,
                          port,
                          value: updated,
                          entity: UpdateEntity.ENERGY_DEVICE,
                        })
                      }
                    />
                  </div>
                  <div>
                    <SelectCtPolarity
                      noBottomLine
                      value={reversePolarity}
                      onSelect={updated =>
                        setUnconfirmedUpdateAction({
                          type: UpdateEnergyDeviceAction.REVERSE_POLARITY,
                          deviceTitle: device.title,
                          id: energyDeviceId,
                          port,
                          value: updated,
                          entity: UpdateEntity.ENERGY_DEVICE,
                        })
                      }
                      error={false}
                    />
                  </div>
                  <div>
                    <SelectPhase
                      noBottomLine
                      value={phase}
                      onSelect={updated =>
                        setUnconfirmedUpdateAction({
                          type: UpdateEnergyDeviceAction.PHASE,
                          deviceTitle: device.title,
                          id: energyDeviceId,
                          port,
                          value: updated,
                          entity: UpdateEntity.ENERGY_DEVICE,
                        })
                      }
                      error={false}
                    />
                  </div>
                  <div>
                    <BreakerDropdown
                      disabled
                      selectedBreaker={data.breaker}
                      selectedBreakerPole={data.breakerPole}
                      energyDeviceId={data.energyDeviceId}
                      port={data.port}
                      deviceTitle={device.title}
                      breakers={breakers}
                      setUnconfirmedUpdateAction={setUnconfirmedUpdateAction}
                    />
                  </div>
                  <div>
                    <Checkbox
                      checked={breaker?.siteTotal}
                      disabled={breaker === undefined}
                      onChange={() =>
                        setUnconfirmedUpdateAction({
                          type: UpdateEnergyDeviceAction.SITE_TOTAL,
                          deviceTitle: device.title,
                          id: energyDeviceId,
                          breakerId: breaker.id,
                          port,
                          value: !breaker.siteTotal,
                          entity: UpdateEntity.BREAKER,
                        })
                      }
                    />
                  </div>
                  <div>
                    {`${getEnergy(
                      energySensorId,
                      energySensorsReadingById
                    )} / ${getPowerFactor(
                      energySensorId,
                      energySensorsReadingById
                    )}`}
                  </div>
                  <div>
                    {getCurrent(energySensorId, energySensorsReadingById)}
                  </div>
                  <div>
                    {newEquipmentPendingAction?.port === port &&
                      newEquipmentPendingAction?.id === energyDeviceId && (
                        <NewEquipmentModal
                          lockSiteId={siteId}
                          breakerIdToUpdate={breakerId || 'new'}
                        />
                      )}
                    <SelectEquipment
                      onCreateEquipmentModalOpen={() => {
                        setNewEquipmentPendingAction({
                          type: UpdateEnergyDeviceAction.BREAKER_EQUIPMENT_ID,
                          deviceTitle: device.title,
                          id: energyDeviceId,
                          port,
                          entity: UpdateEntity.BREAKER,
                        });
                      }}
                      noBottomLine
                      disabled={!breakerId || isMainBreaker}
                      onSelect={updated =>
                        setUnconfirmedUpdateAction({
                          type: UpdateEnergyDeviceAction.BREAKER_EQUIPMENT_ID,
                          deviceTitle: device.title,
                          id: energyDeviceId,
                          port,
                          value: updated,
                          entity: UpdateEntity.BREAKER,
                        })
                      }
                      value={breaker?.equipmentId}
                      siteId={siteId}
                      shortenStringLength={25}
                    />
                  </div>
                  <div>
                    {actuators
                      ? actuators.map(
                          ({ port, portType, id, controlBoardId }, index) => {
                            const subscribedActuatorStates =
                              subscribedControlBoardOutputStates[controlBoardId]
                                ?.state;
                            return (
                              <span
                                key={`device${device.id}actuator${id}`}
                                className={styles.circuitStatus}
                              >
                                {port} /{' '}
                                {renderCircuitStatusText(
                                  port,
                                  portType,
                                  subscribedActuatorStates
                                )}
                              </span>
                            );
                          }
                        )
                      : '-'}
                  </div>
                  <div>
                    <Trash
                      onClick={() =>
                        setUnconfirmedUpdateAction({
                          port,
                          entity: UpdateEntity.DELETE_BREAKER,
                          deviceTitle: device.title,
                          type: UpdateEnergyDeviceAction.DELETE_BREAKER,
                          id: device.id,
                          breakerId: breaker.id,
                        })
                      }
                      className={styles.deleteDeviceIcon}
                      size={16}
                      color="var(--pink-base)"
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

type BreakerDropdownProps = {
  setupMode?: boolean;
  disabled?: boolean;
  deviceTitle: string;
  breakers: CircuitBreakerInPanel[];
  selectedBreaker: CircuitBreakerFromApiResponse | undefined;
  selectedBreakerPole: number | undefined;
  energyDeviceId: number;
  port: number;
  setUnconfirmedUpdateAction: (updateAction: ActionPayLoad | undefined) => void;
};

const BreakerDropdown = ({
  setupMode,
  disabled,
  selectedBreaker,
  selectedBreakerPole,
  deviceTitle,
  energyDeviceId,
  port,
  breakers,
  setUnconfirmedUpdateAction,
}: BreakerDropdownProps) => {
  const reduxDispatch = useDispatch();
  return (
    <div className={setupMode ? styles.breakerDropdownSetupMode : undefined}>
      <Select
        disabled={disabled}
        noBottomLine
        title={
          selectedBreaker
            ? `${selectedBreaker.title} pole ${selectedBreakerPole}` || ''
            : '-'
        }
        variant="select"
        value={selectedBreaker?.title}
      >
        <SelectItem
          isSelected={false}
          onSelect={() => reduxDispatch(showNewCircuitBreakerModal())}
        >
          Add New Circuit Breaker
        </SelectItem>
        {breakers.map(breaker => {
          const {
            breaker: { id: breakerId, type: breakerType },
          } = breaker;
          let breakerPoleCount = 1;
          switch (breakerType) {
            case BreakerType.SINGLE_POLE:
              breakerPoleCount = 1;
              break;
            case BreakerType.DOUBLE_POLE:
              breakerPoleCount = 2;
              break;
            case BreakerType.THREE_POLE:
              breakerPoleCount = 3;
              break;
          }
          let breakerPoleOptions: ReactElement[] = [];
          for (let i = 1; i <= breakerPoleCount; i++) {
            breakerPoleOptions.push(
              <SelectItem
                key={`${energyDeviceId}${port}${breakerId}pole${i}`}
                isSelected={
                  breakerId === selectedBreaker?.id && i === selectedBreakerPole
                }
                onSelect={() => {
                  if (breakerId !== selectedBreaker?.id) {
                    setUnconfirmedUpdateAction({
                      type: UpdateEnergyDeviceAction.BREAKER_ID,
                      deviceTitle,
                      id: energyDeviceId,
                      port,
                      value: breaker.breaker.id,
                      entity: UpdateEntity.BREAKER,
                    });
                  }
                  setUnconfirmedUpdateAction({
                    type: UpdateEnergyDeviceAction.BREAKER_POLE,
                    deviceTitle,
                    id: energyDeviceId,
                    port,
                    value: i,
                    entity: UpdateEntity.ENERGY_DEVICE,
                  });
                }}
              >
                {breaker.breaker.title}
                {breakerPoleCount > 1 ? ` pole ${i}` : ''}
              </SelectItem>
            );
          }
          return (
            <React.Fragment
              key={`${energyDeviceId}${port}${breaker.breaker.id}`}
            >
              {breakerPoleOptions}
            </React.Fragment>
          );
        })}
      </Select>
    </div>
  );
};

const DeleteEnergyDeviceModal = ({
  closeDeleteEnergyDeviceModal,
  onDelete,
  energyDevice,
}) => {
  const actions = (
    <>
      <Button variant="text" onClick={closeDeleteEnergyDeviceModal}>
        Cancel
      </Button>
      <Button onClick={onDelete}>Delete</Button>
    </>
  );

  return (
    <Modal onClose={closeDeleteEnergyDeviceModal} actions={actions}>
      <p style={{ textAlign: 'center' }}>
        Are you sure you want to delete{' '}
        {energyDevice ? <b>{energyDevice.title}</b> : 'this energy device'}?
      </p>
    </Modal>
  );
};

const buildSensorBusNodes = (
  device: EnergyDeviceFromApi | undefined | null,
  resultNodes: EnergyDeviceFromApi[]
) => {
  if (!device) return resultNodes;
  resultNodes.push(device);
  return buildSensorBusNodes(device.busDevice, resultNodes);
};

type DevicePortDisplayData = EnergySensor & {
  energySensorId: number;
  isMainBreaker: boolean;
};

const useEnergyProStreamDataBySensorId = (id: number) => {
  return useSelector<ApplicationState, ObjectById<EnergyProSensorReading>>(
    ({ subscribedEnergyPros }) => {
      const energyProSensorReadings =
        subscribedEnergyPros.byId[id]?.sensors || [];
      return mapArrayToObject(energyProSensorReadings);
    },
    equals
  );
};

const useSensorsFromEnergyPro = (energyPro: EnergyPro | undefined) => {
  return useMemo(() => {
    const energyDeviceSensors = getEnergyDeviceSensorsOfEnergyPro(energyPro);
    return energyDeviceSensors || [];
  }, [energyPro]);
};

const isPortConnectedToMainBreaker = (
  portNumber: number,
  energySensors: EnergySensor[]
) => {
  const sensor = energySensors.find(s => s.port === portNumber);
  if (sensor && sensor.breaker._entity === 'MainBreaker') {
    return true;
  }
  return false;
};

const getEnergy = (
  energySensorId: number,
  energySensorsReadingById: ObjectById<EnergyProSensorReading>
) => {
  const sensorReading: EnergyProSensorReading | undefined =
    energySensorsReadingById[energySensorId];
  const energy: number | undefined = sensorReading?.energy;
  return energy !== undefined ? energy : '-';
};

const getPowerFactor = (
  energySensorId: number,
  energySensorsReadingById: ObjectById<EnergyProSensorReading>
) => {
  const sensorReading: EnergyProSensorReading | undefined =
    energySensorsReadingById[energySensorId];
  const powerFactor: number | undefined = sensorReading?.powerFactor;
  return powerFactor !== undefined ? powerFactor : '-';
};

const getCurrent = (
  energySensorId: number,
  energySensorsReadingById: ObjectById<EnergyProSensorReading>
) => {
  const sensorReading: EnergyProSensorReading | undefined =
    energySensorsReadingById[energySensorId];
  const current: number | undefined = sensorReading?.current;
  return current !== undefined ? current : '-';
};

export default DPSetUpTable;
