import React from 'react';
import { MdLens } from 'react-icons/md';
import { DeviceConnectivityText } from '@energybox/react-ui-library/dist/types';

type Props = {
  connectionStatus?: boolean | string;
  hideText?: boolean;
};

const DeviceConnectionStatus = ({ connectionStatus, hideText }: Props) => {
  return connectionStatus && connectionStatus !== 'INACTIVE' ? (
    <span>
      <MdLens size={10} color="var(--green-base)" />{' '}
      {!hideText && DeviceConnectivityText.ONLINE}
    </span>
  ) : (
    <span>
      <MdLens size={10} color="var(--pink-base)" />{' '}
      {!hideText && DeviceConnectivityText.OFFLINE}
    </span>
  );
};

export default DeviceConnectionStatus;
