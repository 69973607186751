import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../reducers';
import { canAccessFeatures } from '../utils/featureFlag';

type OwnProps = {
  children: React.ReactElement;
  orgWhiteList?: number[];
  legacy?: boolean;
};

interface Props extends OwnProps {
  isVisible: boolean;
}

const FeatureFlag = ({ children, isVisible, legacy }: Props) =>
  isVisible === !legacy ? children : null;

const mapStateToProps = (
  { app }: ApplicationState,
  { orgWhiteList }: OwnProps
) => ({
  isVisible: canAccessFeatures(app, orgWhiteList),
});

export default connect(mapStateToProps)(FeatureFlag);
